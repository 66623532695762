import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { actions as liveFeedActions } from './LiveFeedSlice'
import { getLiveFeedResponseData } from './LiveFeedSelector'
import { getConfig } from 'src/modules/Config/selectors'
import { makeStyles } from '@mui/styles'
import { getTags } from 'src/modules/DougallChat/ChatSelector'
import Ad from 'src/modules/Ads/components/Ad'
import AdsLiveFeed from 'src/modules/LiveFeed/components/Ads/AdsLiveFeed'
import {
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Typography,
  Link,
  Avatar,
  Stack
} from '@mui/material'
import Svg from 'src/components/Svg'
import FwLiveFeed from 'src/images/livefeed.svg'
import usePiwik from 'src/hooks/usePiwik'
import { useChat } from 'src/modules/DougallChat/hooks'

const tagColors = {
  default: '#2d3e50',
  FWReports: '#893535',
  Plus: '#007e67'
}

const refUrlPharma = process.env.REACT_APP_URL
const refUrlHT = process.env.REACT_APP_URL_MEDDEV

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
    cursor: 'pointer',
    marginLeft: '4%',
    marginBottom: '8%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  clickable: {
    cursor: 'pointer',
    textDecoration: 'underline'
  }
}))

const LiveFeedItem = ({
  title,
  description,
  origin,
  link,
  tags = [],
  destination,
  isPlus,
  articleId
}) => {
  const { handleTracking, updateCustomVar } = usePiwik()
  const classes = useStyles()
  const handleArticleClick = () => {
    updateCustomVar('article_id', articleId)
    handleTracking('firstword-ai', 'click', 'live-feed')
    window.open(link, '_blank')
  }
  const buildColor = () =>
    isPlus || destination === 'FWReports'
      ? tagColors[destination] || tagColors.Plus
      : tagColors.default

  const determineOrigin = origin => {
    if (!origin || origin.length === 0) {
      return 'FirstWord Pharma'
    }
    if (origin.includes('Fw4') || origin.includes('Pharma')) {
      return 'FirstWord Pharma'
    } else if (origin.includes('MedDev') || origin.includes('HealthTech')) {
      return 'FirstWord HealthTech'
    }
    return 'FirstWord Pharma'
  }

  const buildTags = () =>
    tags
      .map(tag => (
        <Chip
          key={tag}
          sx={{
            borderRadius: 0,
            marginRight: 1,
            backgroundColor: tagColors[tag] || tagColors.default,
            color: 'white'
          }}
          label={tag}
          size="small"
        />
      ))
      .concat(
        isPlus ? (
          <Chip
            label="+ PLUS"
            key="plus"
            sx={{
              borderRadius: 0,
              marginRight: 1,
              backgroundColor: tagColors.Plus,
              color: 'white'
            }}
            size="small"
          />
        ) : null
      )

  return (
    <Card
      variant="outlined"
      sx={{ marginBottom: 2, cursor: 'pointer' }}
      onClick={handleArticleClick}
    >
      <CardContent>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Avatar sx={{ marginRight: 1, backgroundColor: buildColor() }}>
              FW
            </Avatar>
            <Chip label={determineOrigin(origin)} size="small" />
          </Box>
          <Box>{buildTags()}</Box>
        </Box>
        <Typography variant="subtitle1" gutterBottom>
          {title}
        </Typography>
        {description && (
          <Typography variant="body2" color="text.secondary">
            {description}
            <Link className={classes.clickable}>…read more</Link>
          </Typography>
        )}
      </CardContent>
    </Card>
  )
}

LiveFeedItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  origin: PropTypes.string,
  link: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  destination: PropTypes.any,
  isPlus: PropTypes.bool,
  articleId: PropTypes.string
}
const LiveFeedComponent = () => {
  const dispatch = useDispatch()
  const liveFeedTags = useSelector(getTags())
  const liveFeedData = useSelector(getLiveFeedResponseData())
  const dougalLiveFeedAd1 = useSelector(
    state => state.adsReducer.dougalLiveFeedAd1
  )
  const dougalLiveFeedAd2 = useSelector(
    state => state.adsReducer.dougalLiveFeedAd2
  )
  const dougalLiveFeedAd3 = useSelector(
    state => state.adsReducer.dougalLiveFeedAd3
  )
  const dougalLiveFeedAd4 = useSelector(
    state => state.adsReducer.dougalLiveFeedAd4
  )
  const internalToken = useSelector(getConfig('internal_token')) || null
  const classes = useStyles()
  const { isTyping } = useChat()

  useEffect(() => {
    if (!isTyping) {
      dispatch(liveFeedActions.requestLiveFeed(liveFeedTags))
    }
  }, [dispatch, liveFeedTags, isTyping])

  const buildLiveFeed = () => {
    const elements = []
    let adCount = 0
    let clycleCount = 0
    const maxAds = 4
    const ads = [
      dougalLiveFeedAd1,
      dougalLiveFeedAd2,
      dougalLiveFeedAd3,
      dougalLiveFeedAd4
    ]
    liveFeedData.forEach((feed, index) => {
      const link =
        feed?.origin &&
        (feed.origin.includes('MedDev') || feed.origin.includes('HealthTech'))
          ? `${refUrlHT}/story/${feed.articleId}?at=${internalToken}`
          : `${refUrlPharma}/story/${feed.articleId}`
      elements.push(
        <React.Fragment key={feed.id}>
          <Stack direction="column">
            <LiveFeedItem
              title={feed.title}
              description={feed.description}
              origin={feed.origin}
              link={link}
              tags={[feed.type]}
              isPlus={feed.isPlus}
              destination={feed.tags}
              articleId={feed.articleId}
            />
            <Divider
              variant="middle"
              sx={{ width: '50%', margin: 'auto', color: '#d6d6d6' }}
            />
          </Stack>
        </React.Fragment>
      )

      if (index === 1 || (index > 1 && (index - 1) % 4 === 0)) {
        const adInfo = ads[adCount]
        if (adInfo && !adInfo?.emptyContent) {
          if (adInfo.mediaType === 'json') {
            if (adInfo?.data?.html) {
              elements.push(
                <Ad
                  {...adInfo}
                  ChildComponent={AdsLiveFeed}
                  key={`ad-${adCount}-${clycleCount}`}
                />
              )
              adCount++
            }
          } else {
            elements.push(
              <React.Fragment key={`ad-${adCount}-${clycleCount}`}>
                <Stack direction="column">
                  <Ad
                    className={classes.root}
                    {...adInfo}
                    key={`ad-${adCount}-${clycleCount}`}
                  />
                  <Divider
                    variant="middle"
                    sx={{ width: '50%', margin: 'auto', color: '#d6d6d6' }}
                  />
                </Stack>
              </React.Fragment>
            )
            adCount++
          }

          if (adCount >= maxAds) {
            adCount = 0
            clycleCount++
          }
        }
      }
    })

    return elements
  }

  return (
    <Stack spacing={2}>
      <Typography
        sx={{ textAlign: 'center', paddingTop: '10px' }}
        variant="h5"
        color="text.secondary"
      >
        <Svg src={FwLiveFeed} />
      </Typography>
      {liveFeedData?.length > 0 ? (
        buildLiveFeed()
      ) : (
        <Typography sx={{ textAlign: 'center' }} variant="body2">
          Submit a prompt to see the Live Feed populated with the latest news,
          analysis, reports, or other information related to your prompt, or the
          most recent FirstWord content
        </Typography>
      )}
    </Stack>
  )
}

export default LiveFeedComponent
