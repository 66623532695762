import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import StoryRiverView from '../../StoryRiverView'
import { actions as storyActions } from '../../../modules/SingleStory/slice'
import * as storySelectors from '../../../modules/SingleStory/selector'
import NoTherapyTrendsFound from './children/NoTherapyTrendsFound'

const ReportRelatedUpdates = ({ story, ...props }) => {
  const dispatch = useDispatch()

  const {
    data = {},
    loading = false,
    error = null
  } = useSelector(storySelectors.getRelatedUpdates())

  const { articles = [], criteria = null } = data

  const getTitle = () => {
    if (story?.reportSettings?.reportBrand === 'Therapy Trends') {
      return 'Related Updates'
    }

    if (criteria !== 'latestArticles') {
      return 'Related Reports'
    }

    return 'More FirstWord Reports'
  }

  const view = {
    stories: articles
  }

  useEffect(() => {
    if (story?.id) {
      dispatch(storyActions.requestRelatedUpdates(story))
    }
  }, [story?.id])

  if (!story?.id) return null

  if (criteria === 'therapyTrendsNotFound') {
    return <NoTherapyTrendsFound />
  }

  return (
    <StoryRiverView
      view={view}
      viewsError={error}
      viewsLoading={loading}
      {...props}
      showTitleOnly={true}
      title={getTitle()}
    />
  )
}

ReportRelatedUpdates.propTypes = {
  story: PropTypes.object
}

export default ReportRelatedUpdates
