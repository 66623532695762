import { takeEvery, put, call } from 'redux-saga/effects'
import { actions } from './slice'

function* requestNotices(services, action) {
  const NoticesRepository = services('NoticesRepository')
  const Storage = services('Storage')
  try {
    const { data: remoteNotices } = yield call([
      NoticesRepository,
      'getNotices'
    ])
    const storageNotices = yield call(
      [Storage, 'getItem'],
      'read_notifications',
      []
    )
    const notices = remoteNotices.filter(
      notice => !storageNotices.includes(notice.notification_id)
    )
    yield put(actions.successNotices(notices))
  } catch (e) {
    console.log(e)
    yield put(actions.errorNotices(e))
  }
}

function* dismissNotice(services, action) {
  const {
    payload: { id }
  } = action
  const Storage = services('Storage')
  const Piwik = services('Piwik')
  try {
    const storageNotices = yield call(
      [Storage, 'getItem'],
      'read_notifications',
      []
    )
    const readedNotices = [...storageNotices, id]
    yield call([Storage, 'setItem'], 'read_notifications', readedNotices)
    yield call([Piwik, 'track'], 'header-notification', 'dismiss')
  } catch (e) {
    console.log(e)
    yield put(actions.errorNotices(e))
  }
}

export default function* watchNotices(services) {
  yield takeEvery(actions.requestNotices, requestNotices, services)
  yield takeEvery(actions.dismissNotice, dismissNotice, services)
}
