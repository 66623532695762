import { notificationsEnqueue } from 'src/modules/Notifications/slice'
import DougallMLError from './DougallMLError'
import { dougallEndpoints } from '../../constants'
import { ERR_CANCELED } from './constants'

const DougallMLInterceptors = dispatch => {
  return {
    catchNetworkErrors: async error => {
      if (error.code === ERR_CANCELED) return
      if (!error.response) {
        console.error('The MiddleLayer is unreachable.', error)
        const errorMessage =
          'Something went wrong! Please refresh the page and try again.'
        dispatch(
          notificationsEnqueue({
            message: errorMessage,
            type: 'error'
          })
        )
        throw new Error(errorMessage)
      }
      throw error
    },

    detectMLfailuresOnFail: async error => {
      console.log(error)

      //Validate if the failure is from the history endpoint and only if it is a 404 error or if the error is "ERR_CANCELED".
      if (
        error.code === ERR_CANCELED ||
        (error?.request?.responseURL?.includes(dougallEndpoints.gptHistory) &&
          error?.response?.status === 404)
      )
        return

      if (error.response) {
        const { data: responseError, status } = error.response
        const err = new DougallMLError(
          responseError?.message || '',
          responseError || {},
          status
        )
        throw err
      }
      throw error
    }
  }
}

export default DougallMLInterceptors
