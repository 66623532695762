import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import Page from 'src/components/Page'
import Metadata from 'src/components/Metadata'
import DropDown from 'src/components/ui/DropDown'
import Button from 'src/components/ui/Buttons/Button'
import styles from './SaveSearch.module.sass'
import commonStyles from 'src/styles/common.module.sass'
import Typography from 'src/components/ui/Typography'
import flattenDeep from 'lodash/flattenDeep'

const alerts = {
  none: 'None',
  hourly: 'Notify Me Immediately',
  daily: 'Send Me A Daily Digest of New Matching Results'
  // weekly: 'Weekly'
}

const SaveSearch = ({ handleSubmit, busy, search, navigateToSearch }) => {
  if (!search) {
    navigateToSearch()
    return null
  }

  const action = search?.search?.search_id ? 'Update' : 'Save'

  let filterList = ''
  if (action === 'Update') {
    const {
      search: {
        search_request: { tags = {}, type = [] }
      }
    } = search
    filterList = [
      ...flattenDeep(Object.keys(tags).map(key => tags[key])),
      ...type
    ].join(', ')
  } else {
    const {
      search: { filters = {} }
    } = search
    filterList = flattenDeep(
      Object.keys(filters).map(key => filters[key])
    ).join(', ')
  }

  return (
    <Page>
      <Metadata title="Search Alert" />
      <div className={commonStyles.content}>
        <Typography className={styles.headline} type="headline">
          Save Search
        </Typography>
        <p className={styles.start}>
          Search terms: <strong>{search.name}</strong>{' '}
          {filterList && <Fragment>filtered by {filterList}</Fragment>}
        </p>
        <form className={styles.container} onSubmit={handleSubmit}>
          <div className={styles.inputContainer}>
            <Field
              component={DropDown}
              label="Email notification"
              name="alert"
              placeholder="Select an Alert"
              options={alerts}
              passthrough
            />
            <Typography className={styles.note}>
              Email notifications will only be sent if there are new results
              available for your search.
            </Typography>
          </div>
          <Button
            type="submit"
            tier="secondary"
            className={styles.button}
            textCase="none"
            submitting={busy}
            hasSpinner
          >
            {action} Settings
          </Button>
        </form>
      </div>
    </Page>
  )
}

SaveSearch.propTypes = {
  handleSubmit: PropTypes.func,
  busy: PropTypes.bool,
  search: PropTypes.object,
  navigateToSearch: PropTypes.func
}

export default SaveSearch
