import React, { useState, Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import useRiver from '../../hooks/useRiver'
import useFilters from '../../hooks/useFilters'
import styles from '../../components/River/River.module.sass'
import commonStyles from '../../../../styles/common.module.sass'
import Page from '../../../../components/Page'
import FeaturedContent from '../../components/FeaturedContent'
import RiverHeader from '../../containers/RiverHeaderContainer'
import RiverSubHeader from '../../components/RiverSubHeader'
import RiverBody from '../../containers/RiverBodyContainer'
import RiverSidebar from '../../components/RiverSidebar'
import SearchBar from '../../components/Search/SearchBar'
import SearchForm from '../../components/Search/SearchForm'
import NoResults from '../../components/Search/NoResults'
import DisplayTabsBox from '../../components/MyFW/DisplayTabsBox'
import GroupedArticles from '../../components/MyFW/GroupedArticles'
import RiverMediaEventBody from '../../components/RiverMediaEventBody'
import {
  SIDEBAR_FILTERS,
  SIDEBAR_INTERESTS_AND_FILTERS
} from '../../constants/sidebars'
import {
  contentRiversConfigModule,
  externalSitesConfigModule
} from '../../../../siteConfig/river.js'
import MedicalAbstractsToggle from '../../components/MedicalAbstractsToggle'
import ExternalSiteArticlesToggle from '../../components/ExternalSiteArticlesToggle'
import Metadata from '../../../../components/Metadata'
import { getConfig } from '../../../../modules/Config/selectors'
import cx from 'classnames'
import { useParams } from 'react-router'
import { siteName } from '../../../../utils/oneCodeBase'

const { rivers, mediaEventTypes } = contentRiversConfigModule
const externalSitesConfig = externalSitesConfigModule.default

const RiverSidebarContainer = ({ condition, className, ...props }) => {
  if (condition)
    return (
      <div className={className}>
        <RiverSidebar {...props} />
      </div>
    )

  return null
}

RiverSidebarContainer.propTypes = {
  condition: PropTypes.bool,
  className: PropTypes.string
}

function River() {
  const myfwTabValue = useSelector(
    state => state.contentRiverReducer.myfwTabValue
  )
  const riverStateConfig = useSelector(getConfig('riverConfig')) || {}
  const { flex = 'column' } = riverStateConfig
  const [filtersOpen, setFiltersOpen] = useState(false)
  const toggleFilters = () => setFiltersOpen(!filtersOpen)

  const {
    doSearch,
    sortBy,
    riverId,
    riverKey,
    riverConfig,
    results,
    sponsoredHeadlines,
    count,
    batchCount,
    currentPage,
    riverArgs,
    loadMore,
    infiniteScrollerEnabled,
    title,
    loading,
    loadingMore,
    setMedicalAbstracts,
    setExternalArticlesSearch,
    featuredResults = [],
    doSearchSorting,
    sortMyFW,
    doMyFWSorting,
    isFeaturedLoading
  } = useRiver()

  const {
    sidebar = SIDEBAR_FILTERS,
    hideSubHeader,
    excludeFilterValues,
    viewCount,
    showMedicalAbstractsToggle,
    showExternalArticlesToggle = false,
    showGroupByInterestTabs = false,
    title: configTitle,
    stringTitle,
    showFeaturedContent = false,
    showSingleFeaturedContent = false,
    mediaEventConfig = {}
  } = riverConfig

  const { usedFilters, onRemoveFilter } = useFilters()

  const articlesLoaded = count > 0
  const isSearchFormPage = riverId === ''
  const isSearchResultsPage = riverKey === 'search'
  const isMyFWStoriesPage = riverKey === 'my-fw'
  const isRiverPage = !isSearchFormPage && !isSearchResultsPage
  const sideBarProps = {
    sidebar,
    loading,
    toggleFilters,
    filtersOpen,
    isRiverPage,
    flex,
    ...riverStateConfig?.sidebar
  }

  const { key: searchTerm } = useParams()

  const hndMyfwTabChange = key => () => sortMyFW(key)

  const hndMedicalAbstractsToggle = value => {
    setMedicalAbstracts(value)
  }

  const hndExternalSiteArticlesToggle = (value, searchConfig) => {
    setExternalArticlesSearch(value, searchConfig)
  }

  const getHeaderTitle = title => {
    if (showFeaturedContent && (featuredResults.length || loading))
      return `More ${title} stories`
    return title
  }

  useMemo(() => {
    // Validation when the user puts the MyFW Stories url on the browser
    if (isMyFWStoriesPage && !articlesLoaded) {
      doMyFWSorting()
    }
  }, [isMyFWStoriesPage])

  useMemo(() => {
    if (articlesLoaded && results) {
      // Do search request and update sorting when url params change
      if (isSearchResultsPage) {
        doSearchSorting()
        return
      }
      // Do Myfw Stories request and update sorting when url params change
      if (isMyFWStoriesPage) {
        doMyFWSorting()
        return
      }
    }
  }, [window.location.search])

  return (
    <Page>
      <Metadata title={stringTitle || configTitle} />
      <FeaturedContent
        title={searchTerm}
        loading={isFeaturedLoading}
        views={featuredResults}
        hide={!isFeaturedLoading && !featuredResults.length}
        single={showSingleFeaturedContent}
      />
      <div className={commonStyles.content}>
        <section
          className={cx(
            flex === 'row' ? commonStyles.mainWithoutSidebar : styles.main,
            mediaEventTypes.includes(riverKey) && styles.mediaEvent
          )}
        >
          <RiverHeader
            title={getHeaderTitle(title)}
            showFilterMenu={
              isRiverPage &&
              [SIDEBAR_FILTERS, SIDEBAR_INTERESTS_AND_FILTERS].includes(sidebar)
            } // Only show for filter sidebar
            toggleFilters={toggleFilters}
            riverKey={riverKey}
            riverArgs={riverArgs}
          />

          {flex === 'row' && (
            <RiverSidebarContainer
              condition={!mediaEventTypes.includes(riverKey)}
              {...sideBarProps}
              className={styles.topSidebar}
            />
          )}

          <SearchBar
            hide={loading || isSearchFormPage || isRiverPage}
            toggleFilters={toggleFilters}
            articlesLoaded={articlesLoaded}
            onSortBy={sortBy}
          />

          <DisplayTabsBox
            show={showGroupByInterestTabs}
            value={myfwTabValue}
            onClick={hndMyfwTabChange}
          />

          <RiverSubHeader
            hide={loading || isSearchFormPage || hideSubHeader}
            total={count}
            query={riverArgs}
            filters={usedFilters}
            excludeFilterValues={excludeFilterValues}
            onFilterClick={onRemoveFilter}
            viewCount={viewCount}
          />

          {myfwTabValue === 'byInterest' && riverKey === rivers.MY_FW ? (
            <GroupedArticles />
          ) : (
            <Fragment>
              <div className={styles.toggleContainer}>
                {showExternalArticlesToggle === true &&
                  externalSitesConfig.map((externalSite, index) => (
                    <ExternalSiteArticlesToggle
                      query={riverArgs}
                      loading={loading}
                      onToggle={value =>
                        hndExternalSiteArticlesToggle(
                          value,
                          externalSite?.searchConfig
                        )
                      }
                      label={externalSite?.label}
                      key={`external_site_articles_${index}`}
                    />
                  ))}

                {showMedicalAbstractsToggle && (
                  <MedicalAbstractsToggle
                    query={riverArgs}
                    loading={loading}
                    onToggle={hndMedicalAbstractsToggle}
                  />
                )}
              </div>

              {mediaEventTypes.includes(riverKey) ? (
                <RiverMediaEventBody
                  loading={!isSearchFormPage && loading}
                  results={results}
                  config={mediaEventConfig}
                />
              ) : (
                <RiverBody
                  loading={!isSearchFormPage && loading}
                  results={results}
                  sponsoredHeadlines={sponsoredHeadlines}
                  loadMore={loadMore}
                  infiniteScrollerEnabled={infiniteScrollerEnabled}
                  busyLoadMore={loadingMore}
                  pageStart={currentPage}
                  isRiverPage={isRiverPage}
                  riverStateConfig={riverStateConfig}
                />
              )}
            </Fragment>
          )}

          <SearchForm
            onSubmit={doSearch}
            hide={
              isRiverPage ||
              (loading && isSearchResultsPage) ||
              (articlesLoaded && isSearchResultsPage)
            }
          />

          {!loading && !articlesLoaded && results.length <= 0 && (
            <NoResults riverKey={riverKey} />
          )}
        </section>
        {/* Hidding Sidebar for [events] pages, may bring it back in future releases */}
        {flex === 'column' && (
          <RiverSidebarContainer
            condition={!mediaEventTypes.includes(riverKey)}
            className={styles.sidebar}
            {...sideBarProps}
          />
        )}
      </div>
    </Page>
  )
}

export default River
