import React, { useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import ReCAPTCHA from 'react-google-recaptcha'
import Button from '../../../../components/ui/Buttons/Button'
import { Input } from '../../../../components/ui/Input'
import SelectMenu from '../../../../components/ui/SelectMenu'
import TextArea from '../../../../components/ui/TextArea'
import Typography from '../../../../components/ui/Typography'
import commonStyles from '../../../../styles/common.module.sass'
import styles from './ContactUsForm.module.sass'
import { SessionContext } from '../../../../modules/Session/context'
import { siteName, siteDomain } from '../../../../utils/oneCodeBase'
import { actions } from '../../../../modules/ContactUs/slice'

import { range } from 'lodash'
import GradeController from './GradeController'
import { useDispatch } from 'react-redux'

const teams = () => {
  if (siteName === 'Reports') {
    return {
      [`editorial@${siteDomain}`]: 'Editorial',
      [`subscriptions@${siteDomain}`]: 'Subscriber Services',
      [`feedback@${siteDomain}`]: 'Feedback'
    }
  }

  return {
    [`editorial@${siteDomain}`]: 'Editorial',
    [`advertising@${siteDomain}`]: 'Advertising',
    [`subscriptions@${siteDomain}`]: 'Subscriber Services',
    [`feedback@${siteDomain}`]: 'Feedback'
  }
}

const ContactUsForm = ({ handleSubmit = () => {}, initialValues, change }) => {
  const dispatch = useDispatch()
  const { user } = useContext(SessionContext)
  const recaptchaRef = useRef()

  const handleRecaptcha = value => {
    // Update the 'recaptcha' field with the reCAPTCHA value
    change('cap', value)
  }

  const handleChange = (field, value) => {
    change(field, value)
  }

  const handleSubmitWithRecaptcha = async values => {
    if (siteName !== 'Reports') {
      const recaptchaValue = await recaptchaRef.current.executeAsync()
      handleRecaptcha(recaptchaValue)
      values.cap = recaptchaValue
      recaptchaRef.current.reset()
    }

    dispatch(actions.requestSendMessage(values))
  }

  let scoreArray = range(11)

  return (
    <form
      onSubmit={handleSubmit(handleSubmitWithRecaptcha)}
      className={styles.form}
    >
      <Field
        component={Input}
        name="name"
        label="Full Name"
        readOnly={Boolean(initialValues.name)}
      />
      <Field
        component={Input}
        name="email"
        label="Email"
        readOnly={Boolean(initialValues.email)}
      />
      {user?.premiumAccountConsultant?.active ? (
        <div className={styles.marginTop}>
          <Typography className={commonStyles.bold} type="body2">
            FirstWord Consultant:
          </Typography>
          <Typography type="body2">
            {`${user.premiumAccountConsultant.first_name} ${user.premiumAccountConsultant.last_name}`}
          </Typography>
        </div>
      ) : (
        siteName !== 'Edge' && (
          <Field
            component={SelectMenu}
            label="Team"
            name="team"
            placeholder="Select the team to contact"
            options={teams()}
            passthrough
          />
        )
      )}
      <Field
        component={TextArea}
        name="message"
        autosize={true}
        label="Comment"
        labelFloat={true}
        resize="inline"
        minHeight={160}
      />

      <Field
        name="grade"
        handleChange={handleChange}
        styles={styles}
        scoreArray={scoreArray}
        component={GradeController} // no need to render anything
      />

      <Field
        name="cap"
        component={() => null} // no need to render anything
      />

      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} // Replace this with your Site key
        onChange={handleRecaptcha}
      />
      <Button
        type="submit"
        tier="secondary"
        className={styles.button}
        textCase="none"
      >
        Send Message
      </Button>
    </form>
  )
}

ContactUsForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  change: PropTypes.func
}

export default ContactUsForm
