import React from 'react'
import Page from '../../../../components/Page'
import RegisterEmailSent from './components/RegisterEmailSent'
import cx from 'classnames'
import styles from '../../../../modules/Static/Static.module.sass'

const EmailSentPage = () => {
  return (
    <Page className={cx(styles.static, styles.centered)}>
      <RegisterEmailSent />
    </Page>
  )
}

export default EmailSentPage
