import React from 'react'
import { Link } from 'react-router-dom'
import styles from 'src/styles/common.module.sass'
import Typography from 'src/components/ui/Typography'
import myReportViewStyles from '../MyReportsView.module.sass'
import ReactHtmlParser from 'react-html-parser'
import PropTypes from 'prop-types'
import StoryRiver from 'src/components/StoryRiver'

export const NoReportsFoundView = ({ title }) => {
  return (
    <StoryRiver title={ReactHtmlParser(title)} titleType="title">
      <div className={myReportViewStyles.description}>
        <Typography variant="body1">
          You currently do not have any FirstWord Reports available to you.{' '}
          <Link to="/contact-us" className={styles.activeLink}>
            Contact Us
          </Link>{' '}
          to learn more about our reports and how you gain access.
        </Typography>
      </div>
    </StoryRiver>
  )
}

NoReportsFoundView.propTypes = {
  title: PropTypes.string
}

export default NoReportsFoundView
