import React, { useRef, useContext } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Typography from 'src/components/ui/Typography'
import FullImage from 'src/components/FullImage'
import Interests from 'src/components/Interests'
import { fromNow } from 'src/utils'
import { licenseTypes } from 'src/services/FW5ML/parsers/UserParser'
import { SessionContext } from 'src/modules/Session/context'
import styles from './StoryExcerptRiver.module.sass'
import useComponentSize from '@rehooks/component-size'
import throttle from 'lodash/throttle'
import classNames from 'classnames/bind'
import ViewMoreBox from 'src/components/ViewMoreBox'
import ReactHtmlParser from 'react-html-parser'
import SocialButtons from 'src/components/SocialButtons'
import copyToClipboard from 'src/utils/copyToClipboard'
import { notificationsEnqueue } from 'src/modules/Notifications/slice'
import { piwikRequest } from 'src/modules/Piwik/slice'
import { getConfigFilteredTags } from 'src/modules/Config/selectors'
import { siteName } from 'src/utils/oneCodeBase'

const cx = classNames.bind(styles)

const Article = ({ title, sidebar }) => (
  <Typography
    type="title"
    className={cx(styles.title, { sidebar })}
    gutterBottom
  >
    {title}
  </Typography>
)

Article.propTypes = {
  title: PropTypes.string,
  sidebar: PropTypes.string
}

const StoryExcerptRiver = ({
  congress,
  title: titleSource = 'Title',
  to = '/',
  published,
  image = null,
  body,
  teaser,
  tags = [],
  source,
  author,
  interestsClickable = false,
  showInterests = true,
  showRef = false,
  articleSettings = {},
  id = ''
}) => {
  const flag = siteName === 'Edge'
  const socialHandler = flag ? '@fw_edge' : '@fwpharma'
  const socialResource = flag ? 'firstwordedge.com' : 'firstwordpharma.com'
  const authorSettingsFound = articleSettings?.authors?.find(
    item => item.name === author
  )
  const authorImg =
    authorSettingsFound?.icon || articleSettings.author_icon_default

  const ref = useRef(null)
  const throttledComponentSize = throttle(useComponentSize, 250)
  const size = throttledComponentSize(ref)
  const { width } = size
  const sidebar = width < 420
  const congressTitle = congress ? `: Presented at ${congress.join(', ')}` : ''
  const title = `${titleSource}${congressTitle}`

  const { user } = useContext(SessionContext)

  const getUrl = () => `${window.location}`

  const dispatch = useDispatch()

  const filteredTags =
    useSelector(
      getConfigFilteredTags({
        interests: tags
      })
    ) || tags

  const hndSocialIconClick = socialNetwork => e => {
    e.preventDefault()

    // Piwik tracking
    dispatch(
      piwikRequest({
        customVarUpdate: {
          key: 'article_id',
          value: id
        },
        tracking: {
          category: 'home',
          action: 'share-click',
          name: socialNetwork === 'link' ? 'copy-url' : socialNetwork
        }
      })
    )

    if (socialNetwork === 'link') {
      copyToClipboard(`${getUrl()}${to}`)
      dispatch(
        notificationsEnqueue({
          message: 'Article URL was copied to your clipboard'
        })
      )
      return
    }
    const linkBuilders = {
      twitter: () => {
        const message = encodeURIComponent(
          `${title} ${getUrl()} via ${socialHandler}`
        )
        return `https://twitter.com/intent/tweet?text=${message}`
      },
      linkedin: () => {
        const summary = body.length > 50 ? body.substr(0, 50) + '...' : body
        return `http://www.linkedin.com/shareArticle?mini=true&url=${getUrl()}&title=${encodeURIComponent(
          title
        )}&summary=${encodeURIComponent(summary)}&source=${socialResource}`
      }
    }
    if (!linkBuilders[socialNetwork]) return
    const link = linkBuilders[socialNetwork]()
    window.open(link, 'pop', 'width=600, height=400, scrollbars=no')
  }
  const styleTeaser =
    user.license !== licenseTypes.LICENSE_PLUS ? styles.bodyFree : styles.body
  return (
    <article ref={ref} className={styles.container}>
      <header>
        <div className={styles.authorInfoContainer}>
          {authorImg && <img src={authorImg} alt={''} />}
          <div>
            {author && <div className={styles.author}>{author}</div>}
            {published && (
              <div className={styles.published}>
                PUBLISHED: {fromNow(published)}
              </div>
            )}
          </div>
        </div>

        {title && (
          <Link to={to} className={styles.link}>
            <Article title={title} sidebar={sidebar} />
          </Link>
        )}
      </header>
      {image && (
        <div className={styles.imageContainer}>
          <Link to={to}>
            <FullImage
              className={styles.image}
              src={image}
              objectFit="cover"
              width="100%"
              height="100%"
            />
          </Link>
        </div>
      )}
      <div className={styles.content}>
        {teaser && (
          <Typography className={styleTeaser} type="body1">
            {ReactHtmlParser(teaser)}
          </Typography>
        )}
        {showRef && source && (
          <p className={styles.source}>{source && `Ref: ${source}`}</p>
        )}
        {showInterests && !!filteredTags.length && (
          <Interests
            tags={filteredTags}
            clickable={interestsClickable}
            parentStyle={{ fontSize: '15px', marginTop: '30px' }}
          />
        )}

        <footer className={styles.articleFooter}>
          <SocialButtons onClick={hndSocialIconClick} />
          <ViewMoreBox onClick={() => {}} to={to}>
            Read More
          </ViewMoreBox>
        </footer>
      </div>
    </article>
  )
}

StoryExcerptRiver.propTypes = {
  congress: PropTypes.array,
  title: PropTypes.string,
  to: PropTypes.string,
  published: PropTypes.string,
  image: PropTypes.string,
  body: PropTypes.string,
  teaser: PropTypes.string,
  tags: PropTypes.array,
  source: PropTypes.string,
  author: PropTypes.string,
  interestsClickable: PropTypes.bool,
  showInterests: PropTypes.bool,
  showRef: PropTypes.bool,
  articleSettings: PropTypes.object,
  id: PropTypes.string
}

export default StoryExcerptRiver
