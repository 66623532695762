import { useEffect, useContext, useState } from 'react'
import classNames from 'classnames/bind'
import Page from 'src/components/Page'
import { SessionContext } from 'src/modules/Session/context'
import commonStyles from 'src/styles/common.module.sass'
import SingleStory from 'src/components/SingleStory'
import styles from './SimpleStoryPage.module.sass'
import imgFakeArticle from 'src/images/singleStoryFake.png'
import Metadata from 'src/components/Metadata'
import useSingleStory from 'src/hooks/useSingleStory'
import PropTypes from 'prop-types'

const cx = classNames.bind(commonStyles)

const SimpleStoryPage = ({ match, fromXML }) => {
  const sessionContext = useContext(SessionContext)
  const { user } = sessionContext
  const { requestSingleStory, story, loading } = useSingleStory()

  const [renderPage, setRenderPage] = useState(false)
  useEffect(() => {
    if (fromXML) {
      createSingleStoryLink(match.params, history)
    } else {
      setRenderPage(true)
    }
  }, [])

  useEffect(() => {
    requestSingleStory(match?.params?.id)
  }, [match?.params?.id])

  const createSingleStoryLink = (params, history) => {
    // Using window.location.href to push and reload the page, in order to process the auth token
    window.location.href = `/print/${params.id}?t=${params.login}`
  }

  if (!renderPage) {
    return <></>
  }
  return (
    <Page simplePage>
      <Metadata
        title={story.title}
        description={story.teaser}
        image={story.image}
      />

      <Metadata
        title={story.title}
        description={story.teaser}
        image={story.image}
      />
      <div
        className={commonStyles.content}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <section className={cx(commonStyles.main)}>
          {!loading && (
            <SingleStory
              key={`single-story-${story.id}`}
              title={story.title}
              source={story.source}
              published={story.published}
              author={story.author}
              image={story.image}
              tags={story.tags}
              congress={story.congress}
              teaser={story.teaser}
              type={story.type}
              typeTo={`/river/type/${story.type}`}
              body={story.body}
              simplePage={true}
              id={story.id}
              user={user}
              allTags={story.allTags}
              likes={story.likes}
              likedByProfile={story.likedByProfile}
            />
          )}
          {loading && (
            <div
              style={{
                backgroundImage: `url('${imgFakeArticle}')`
              }}
              className={styles.fakeContent}
            ></div>
          )}
        </section>
      </div>
    </Page>
  )
}

SimpleStoryPage.propTypes = {
  match: PropTypes.object,
  fromXML: PropTypes.bool
}

export default SimpleStoryPage
