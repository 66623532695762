import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import Header from '../../modules/Header/HeaderContainer'
import Footer from '../Footer'
import styles from './RenderFullPage.module.sass'
import TopLeaderboardAd from '../TopLeaderboardAd'
import { actions as adActions } from '../../modules/Ads/slice'
import adsList from '../../modules/Ads/adsList'
import cx from 'classnames'

const RenderFullPage = props => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(adActions.requestAds({ ...adsList }))

    return () => {
      dispatch(adActions.cleanAds())
    }
  }, [location.pathname])

  return (
    <div className={styles.page}>
      <TopLeaderboardAd />
      <Header hideMenu={props?.hideMenu} />

      <main
        className={cx(
          props?.noScrollableContent
            ? styles.noScrollableContent
            : styles.content,
          props?.className
        )}
      >
        {props?.children}
      </main>

      {!props?.hideFooter && <Footer />}
    </div>
  )
}

RenderFullPage.propTypes = {
  hideMenu: PropTypes.bool,
  noScrollableContent: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  hideFooter: PropTypes.bool
}

export default RenderFullPage
