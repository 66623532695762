import { takeEvery, call, put } from 'redux-saga/effects'
import { actions } from './slice'
import { logout } from 'src/modules/Session/actions'
import FW5MLError from 'src/services/FW5ML/FW5MLError.js'
import { ACCESS_DENIED } from 'src/services/FW5ML/errorCodes.js'

function* catalogRequest(key, catalogName, CatalogsRepository) {
  try {
    const catalog = yield call([CatalogsRepository, 'getCatalog'], catalogName)
    yield put(actions.successCatalogs({ key, catalog }))
  } catch (e) {
    if (e instanceof FW5MLError) {
      const code = e.getCode()
      if (code === ACCESS_DENIED) {
        yield put(logout({ reload: false, redirect: '/sign-in' }))
        return
      }
    }
    yield put(actions.errorCatalogs({ key, error: e.message }))
  }
}

function* catalogsRequest(services, action) {
  const CatalogsRepository = services('CatalogsRepository')
  const { payload: catalogs } = action
  for (let key in catalogs) {
    yield call(catalogRequest, key, catalogs[key], CatalogsRepository)
  }
}
export default function* watchCatalogs(services) {
  yield takeEvery(actions.requestCatalogs, catalogsRequest, services)
}
