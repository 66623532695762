import { connect } from 'react-redux'
import { actions } from 'src/modules/MobileMenu/slice'
import { actions as searchActions } from 'src/modules/River/slice'
import MobileMenu from 'src/modules/MobileMenu/components/MobileMenu'

const mapDispatchToProps = dispatch => ({
  doSearchCall: query => dispatch(searchActions.redirectSearch(query)),
  handleSlideMenu: () => dispatch(actions.toggleLeftMenu())
})

const mapStateToProps = state => ({
  open: state.mobileMenuReducer.leftMenuOpen,
  position: 'left'
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu)
