import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import StoryExcerptRiver from 'src/components/StoryExcerptRiver'
import Button from 'src/components/ui/Buttons/Button'
import styles from './DynamicRiverBody.module.sass'
import StorySpinner from 'src/components/StorySpinner'
import { getConfig } from 'src/modules/Config/selectors'

const DynamicRiverBody = ({
  config = {},
  id = '',
  dynamicRivers = {},
  interestsClickable = false,
  showInterests = true,
  getRiverContent = () => {},
  loadMoreRiverContent = () => {},
  ...props
}) => {
  const formatRequestBody = { riverId: id, config }
  const currentRiver = dynamicRivers[id] || {}
  const articleSettings = useSelector(getConfig('article_settings')) || {}
  const {
    count: total,
    results = [],
    loading = true,
    loadingMore = false
  } = currentRiver

  useEffect(() => {
    getRiverContent(formatRequestBody)
  }, [])

  return (
    <>
      {loading ? (
        <StorySpinner />
      ) : (
        results && (
          <>
            {results.map(result => (
              <StoryExcerptRiver
                key={`dynamic-river-story-${result.id}`}
                {...result}
                id={result.id}
                to={`story/${result.id}`}
                type={result.type}
                typeTo={`/river/type/${result.type}`}
                title={result.title}
                published={result.published}
                tags={result.tags}
                congress={result.congress}
                plus={result.flags.isPaid}
                source={null}
                interestsClickable={interestsClickable}
                showInterests={showInterests}
                articleSettings={articleSettings}
              />
            ))}
            {results.length > 0 && results.length < total && (
              <div className={styles.loadMore}>
                <Button
                  onClick={() => loadMoreRiverContent(formatRequestBody)}
                  minWidth="40%"
                  textCase="none"
                  tier="secondary"
                  submitting={loadingMore}
                  hasSpinner
                >
                  Load More
                </Button>
              </div>
            )}
          </>
        )
      )}
    </>
  )
}

DynamicRiverBody.propTypes = {
  config: PropTypes.object,
  id: PropTypes.string,
  dynamicRivers: PropTypes.object,
  interestsClickable: PropTypes.bool,
  showInterests: PropTypes.bool,
  getRiverContent: PropTypes.func,
  loadMoreRiverContent: PropTypes.func
}

export default DynamicRiverBody
