import React, { useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Page from '../../components/Page'
import commonStyles from '../../styles/common.module.sass'
import { historyHandler } from 'src/utils'
import { useHistory } from 'react-router-dom'
import { getConfig } from '../Config/selectors'
import styles from './OnboardingFlow.module.sass'
import Typography from '../../components/ui/Typography'
import Newsletters from './children/Newsletters'
import { actions as configActions } from '../Config/slice'
import { useFlags } from 'launchdarkly-react-client-sdk'
import usePiwik from '../../hooks/usePiwik'
import { SessionContext } from '../Session/context'
import cx from 'classnames'

const useNewsletterPromoVisibility = () => {
  return useSelector(getConfig('newsletter_promo_visibility')) || {}
}

const useNewsletterPromo = () => {
  return useSelector(getConfig('newsletter_promo')) || {}
}

const useRouter = () => {
  return useSelector(state => state.router)
}

const OnboardingFlow = () => {
  const flags = useFlags()
  const history = useHistory()
  const dispatch = useDispatch()
  const router = useRouter()
  const { handleTracking } = usePiwik()
  const { user } = useContext(SessionContext)
  const { visible = false } = useNewsletterPromoVisibility()
  const { disclaimer, title, description, newsletters } = useNewsletterPromo()

  useEffect(() => {
    // Redirect to home if user does not come from registration flow
    // or if there are no newsletters from config
    if (
      visible &&
      user &&
      (newsletters?.free?.length > 0 || newsletters?.plus?.length > 0)
    ) {
      return handleTracking('page', 'view', 'newsletter-onboarding')
    }

    if (process.env.NODE_ENV !== 'development' && !flags['debug']) {
      return historyHandler('/', '', history, 'replace')
    }
  }, [])

  useEffect(() => {
    // After component unmounts
    return () => {
      if (visible) {
        dispatch(
          configActions.updateConfigRequest({
            key: 'newsletter_promo_visibility',
            data: { visible: false }
          })
        )
      }
    }
  }, [router?.location?.pathname])

  return (
    <Page className={commonStyles.content}>
      <div
        className={cx(
          commonStyles.content,
          commonStyles.centered,
          styles.nlContainer
        )}
      >
        <h2 className={styles.title}> {title} </h2>
        <Typography className={styles.description}>{description}</Typography>
        <Newsletters configNewsletters={newsletters} user={user} />
        <Typography className={styles.disclaimer}>{disclaimer}</Typography>
      </div>
    </Page>
  )
}

export default OnboardingFlow
