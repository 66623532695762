import {
  takeEvery,
  takeLatest,
  all,
  call,
  select,
  put
} from 'redux-saga/effects'
import { LOCATION_CHANGE } from 'connected-react-router'
import { actions } from './slice'
import { licenseTypes } from 'src/services/FW5ML/parsers/UserParser'
import { getPageFound } from 'src/modules/Config/utils'
import { arrayify } from 'src/utils'
import { getAnonArticleLimitSettings } from 'src/modules/Config/selectors'
import { recursiveAddTokenToMenus } from './helpers'
// import { configs } from 'src/constants/testEdge'
// import { configs } from 'src/constants/testph'
// import { configs } from 'src/constants/testHT'
// import { configs } from 'src/constants/testFWReports'

function* addConfigRequest(services, { payload: { key, data = null } }) {
  const CatalogsRepository = services('CatalogsRepository')
  try {
    if (!data) {
      data = yield call([CatalogsRepository, 'getCatalog'], key)
    }
    yield put(actions.addConfigSuccess({ key, data }))
  } catch (e) {
    console.error(e)
    yield put(actions.addConfigError({ key, error: e.message }))
  }
}

function* initialConfigRequest(services, { payload }) {
  const CatalogsRepository = services('CatalogsRepository')
  const Session = services('Session')
  const token = yield call([Session, 'tokenLoad'])
  const user = yield call([Session, 'userLoad'])

  // Set internal token to state to use in different components
  if (token && user) {
    yield put(actions.addConfigSuccess({ key: 'internal_token', data: token }))
  }

  // Fetch configs from the server
  const configs = yield call([CatalogsRepository, 'getCatalog'], 'configs') //actual ML request

  // Save config to redux state
  for (const key of Object.keys(configs)) {
    const data = configs[key]

    if (key === 'menus') {
      yield put(
        actions.addConfigSuccess({
          key,
          data: recursiveAddTokenToMenus(data, token, user)
        })
      )
      continue
    }

    yield call(addConfigRequest, services, { payload: { key, data } })
  }
  yield put(actions.initialConfigSuccess())
}

function* updateConfigRequest(services, { payload: { key, data } }) {
  try {
    yield put(actions.updateConfigSuccess({ key, updatedData: data }))
  } catch (e) {
    console.error(e)
    yield put(actions.updateConfigError({ key, error: e.message }))
  }
}

function* handleRegisterModalURLChange(services, action) {
  const Session = services('Session')
  const user = yield call([Session, 'userLoad'])
  const { license = '' } = user

  // Reset Paywall/Register Modal showing up
  // Show register popup if the flow defines it for free users
  const anonArticleLimitSettings = yield select(getAnonArticleLimitSettings())
  const { registerPopUpFlow = {} } = anonArticleLimitSettings

  const showRegisterModalForFree = registerPopUpFlow?.show_for_free || false

  const showRegisterModalForAnonymous =
    registerPopUpFlow?.show_for_anon || false

  const pagesToInclude = registerPopUpFlow?.uri_path
    ? arrayify(registerPopUpFlow.uri_path)
    : []
  const pageFound = getPageFound(pagesToInclude)

  const showRegisterPaywall =
    (showRegisterModalForFree &&
      pageFound &&
      license === licenseTypes.LICENSE_FREE) ||
    (showRegisterModalForAnonymous && pageFound && !user?.profile_id) ||
    false

  yield put(
    actions.updateConfigRequest({
      key: 'account_settings',
      data: {
        showRegisterPaywall
      }
    })
  )
}

export default function* watchConfig(services) {
  yield all([
    takeEvery(actions.addConfigRequest, addConfigRequest, services),
    takeLatest(actions.initialConfigRequest, initialConfigRequest, services),
    takeLatest(actions.updateConfigRequest, updateConfigRequest, services),
    takeLatest(LOCATION_CHANGE, handleRegisterModalURLChange, services),
    takeLatest(
      actions.getConfigRegisterModalValidation,
      handleRegisterModalURLChange,
      services
    )
  ])
}
