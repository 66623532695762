// libraries
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { useSelector, useDispatch } from 'react-redux'
// UI COMPONENTS
import Typography from '../../../ui/Typography'
import { CustomizableModal } from '../../../CustomizableModal'
import { Input } from '../../../ui/Input'
import Button from '../../../ui/Buttons/Button'
import TextArea from '../../../ui/TextArea'
// actions
import { actions } from '../../slice'
//Styles
import styles from './EmailShareModal.module.sass'
// validations
import validate from './validate'
import warn from './warn'
import usePiwik from '../../../../hooks/usePiwik'

const EmailShareModal = ({
  open = false,
  actionType = '',
  articleId = '',
  modalInfo = {},
  onClose = () => {},
  handleSubmit = () => {}
}) => {
  const dispatch = useDispatch()

  const isLoading = useSelector(state => state?.actionButtonsReducer?.busy)
  const { handleTrackingWithArticleIdProp } = usePiwik()

  const onInputSubmit = async values => {
    if (onSuccess) {
      onSuccess(values)
    }
  }

  const onSuccess = values => {
    const data = { ...values, articleId }
    if (actionType === 'article-share') {
      handleTrackingWithArticleIdProp(
        'pop-up_share-email',
        'button-click',
        'submit',
        articleId
      )
      dispatch(actions.requestArticleShare({ data }))
    }
    if (actionType === 'site-share') {
      handleTrackingWithArticleIdProp(
        'pop-up_share-invite-colleague',
        'button-click',
        'submit',
        articleId
      )
      dispatch(actions.requestSiteShare({ data }))
    }
    onClose()
  }

  const {
    header_text = '',
    email_field = '',
    message_field = '',
    submit_button = ''
  } = modalInfo

  return (
    <CustomizableModal
      open={open}
      onClose={onClose}
      modalStyles={{ zIndex: '81' }}
      modalContentStyles={{ padding: '15px 15px 5px 15px' }}
      width="400px"
      height="auto"
      isLoading={isLoading}
    >
      <div>
        <div className={styles.header}>
          <Typography
            type="title"
            gutterBottom
            paragraph
            className={styles.title}
          >
            {header_text}
          </Typography>
        </div>

        <form onSubmit={handleSubmit(onInputSubmit)}>
          <div>
            <Field
              className={styles.field}
              component={Input}
              label={email_field}
              name="email_address"
              errorAlign="center"
              traditional={true}
              placeholder="example@example.com"
            />
          </div>
          <Field
            className={styles.textArea}
            component={TextArea}
            label={message_field}
            name="email_message"
            errorAlign="center"
            traditional={true}
          />

          <div className={styles.submitBtn}>
            <Button
              type="submit"
              tier="secondary"
              submitting={isLoading}
              className={styles.button}
              hasSpinner
            >
              {submit_button}
            </Button>
          </div>
        </form>
      </div>
    </CustomizableModal>
  )
}

EmailShareModal.propTypes = {
  open: PropTypes.bool,
  articleId: PropTypes.any,
  onClose: PropTypes.func,
  actionType: PropTypes.string,
  modalInfo: PropTypes.object,
  handleSubmit: PropTypes.func
}

export default reduxForm({
  touchOnBlur: false,
  destroyOnUnmount: true,
  enableReinitialize: true,
  forceUnregisterOnUnmount: true,
  form: 'article-share',
  warn,
  validate
})(EmailShareModal)
