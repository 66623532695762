import React, { Fragment, useCallback, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import styles from './TopLeaderboardAd.module.sass'
import { useDispatch, useSelector } from 'react-redux'
import { getLandingPagesRoutes } from '../../modules/GatedLandingPage/selectors'
import { getConfig } from '../../modules/Config/selectors'
import { getPageNotFound } from '../../modules/Config/utils'
import Ad from '../../modules/Ads/components/Ad'
import ShowForMediaSize from '../Responsive/ShowForMediaSize'
import { actions as configActions } from '../../modules/Config/slice'
import { useLocation } from 'react-router-dom'

const TopLeaderboard = ({ ad, ...props }) => {
  const dispatch = useDispatch()
  const topSpaceHeight = ad?.topSpaceHeight || 110
  const landingPagesRoutes = useSelector(getLandingPagesRoutes()) || []
  const headerSettings = useSelector(getConfig('headerSettings')) || {}
  const adObserverRef = useRef()
  const adRef = useRef()
  const { fixed = true } = headerSettings

  const handleHeaderScroll = data =>
    dispatch(configActions.updateConfigRequest(data))

  // Merge excluded pages and landing pages (if applies)
  const adPagesToExclude =
    ad && ad.emptyContent !== undefined && ad.emptyContent === false
      ? [
          ...(ad.pagesToExclude || []),
          ...(ad.exclude_landing_pages ? landingPagesRoutes : [])
        ]
      : []

  const validAdPage = getPageNotFound(adPagesToExclude) || false

  const handleScroll = () => {
    const adHeight = adRef?.current?.offsetHeight - window.scrollY + 1

    handleHeaderScroll({
      key: 'headerSettings',
      data: {
        fixed: false,
        adHeight,
        scroll: window.scrollY
      }
    })
  }

  const adElementRef = useCallback(
    node => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleScroll)

      if (adObserverRef.current) adObserverRef.current.disconnect()
      if (!window.IntersectionObserver) return

      adObserverRef.current = new IntersectionObserver(entries => {
        window.removeEventListener('scroll', handleScroll)
        window.removeEventListener('resize', handleScroll)

        if (
          !validAdPage ||
          !ad?.area ||
          (ad.ready && ad.emptyContent === true) ||
          !entries ||
          !entries?.length > 0 ||
          !entries[0]?.isIntersecting
        ) {
          handleHeaderScroll({
            key: 'headerSettings',
            data: { fixed: true, scroll: 0, adHeight: 0 }
          })
          return
        }

        if (
          ad.ready &&
          ad.emptyContent !== undefined &&
          ad.emptyContent === false
        ) {
          handleScroll()
          window.addEventListener('scroll', handleScroll)
          window.addEventListener('resize', handleScroll)
        }
      })
      if (node) adObserverRef.current.observe(node)
    },
    [ad]
  )

  return (
    <Fragment>
      <div ref={adRef}>
        {validAdPage && ad?.area && (
          <Fragment>
            {ad.ready &&
            ad.emptyContent !== undefined &&
            ad.emptyContent === false ? (
              <div className={styles.adContainer}>
                <Ad className={styles.ad} {...ad} />
              </div>
            ) : (
              <Fragment>
                {fixed === false && !ad.ready && (
                  <div
                    className={styles.blankSpace}
                    style={{ height: `${topSpaceHeight}px` }}
                  ></div>
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
      <div ref={adElementRef} />
    </Fragment>
  )
}

TopLeaderboard.propTypes = {
  ad: PropTypes.object
}

const TopLeaderboardAd = () => {
  const ads = useSelector(state => state?.adsReducer) || {}

  const location = useLocation()

  const { area, src, ...topAdSettings } = ads?.topAd ?? {}

  const adZone = useMemo(() => {
    let adToDisplay = ads.leaderboardHomeAd
    if (location.pathname.includes('/story')) {
      adToDisplay = ads?.leaderboardStoryAd
    } else if (location.pathname.includes('/river')) {
      adToDisplay = ads?.leaderboardRiverAd
    }
    if (adToDisplay?.emptyContent || !adToDisplay?.ready) {
      return
    }
    return { ...adToDisplay, ...topAdSettings }
  }, [location.pathname, ads])

  const topLeaderboardAdProps = useMemo(
    () => ({
      showFor: ['large', 'medium', 'small'],
      ad: adZone,
      name: adZone?.id ?? 'topAd'
    }),
    [adZone]
  )

  const adRef = useRef(null)

  return (
    <ShowForMediaSize
      showFor={topLeaderboardAdProps.showFor}
      key={`top-leaderboard-ad-${topLeaderboardAdProps.name}`}
    >
      <TopLeaderboard ad={topLeaderboardAdProps.ad} adRef={adRef} />
    </ShowForMediaSize>
  )
}

export default React.memo(TopLeaderboardAd)
