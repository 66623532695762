import {
  takeEvery,
  call,
  put,
  fork,
  select,
  takeLatest
} from 'redux-saga/effects'
import { actions } from './slice'
import { getConfig } from 'src/modules/Config/selectors'
import { actions as configActions } from 'src/modules/Config/slice'

function* getLayoutAds(services, ads) {
  const CatalogsRepository = services('CatalogsRepository')
  try {
    const key = 'ads'
    let layoutAds = yield select(getConfig(key)) || {}

    // Request layout ads if they are not declared on state
    if (!layoutAds) {
      const configs = yield call([CatalogsRepository, 'getCatalog'], 'configs')
      layoutAds = configs[key] || {}
      yield put(
        configActions.addConfigSuccess({
          key,
          data: layoutAds
        })
      )
    }

    return { ...ads, ...layoutAds }
  } catch (e) {
    return ads
  }
}

function* adRequest(key, config, Ads) {
  try {
    const ad = yield call([Ads, 'generateAd'], config)
    yield put(actions.successAds({ key, ad }))
  } catch (e) {
    yield put(actions.errorAds({ key, error: e.message }))
  }
}

function* adsRequest(services, action) {
  const Ads = services('Ads')
  const { payload: ads } = action

  const newAds = yield call(getLayoutAds, services, ads)

  // Remove prestitial ad from ads to avoid rendering it twice
  delete newAds?.prestitialAd

  const pathname = window.location.pathname
  const filteredAds = {}

  // Validate if ad should be requested based on route pathname
  for (let key in newAds) {
    const ad = newAds[key]
    let validAdRoute = true

    if (ad?.showForRoutes) {
      validAdRoute = ad.showForRoutes.some(route => {
        return route?.exactMatch
          ? route?.path === pathname
          : pathname.includes(route?.path)
      })
    }

    if (ad?.pagesToExclude) {
      validAdRoute = !ad.pagesToExclude.includes(pathname)
    }

    if (validAdRoute) {
      filteredAds[key] = ad
    }
  }

  yield put(actions.registerAds(filteredAds))

  for (let key in filteredAds) {
    yield fork(adRequest, key, filteredAds[key], Ads)
  }
}

function* prestitialAdRequest(services, action) {
  const Ads = services('Ads')
  let ad = { emptyContent: true }

  try {
    const prestitialAd = yield select(
      state => state?.configReducer?.ads?.data?.prestitialAd
    )

    if (prestitialAd) {
      ad = yield call([Ads, 'generateAd'], prestitialAd)
    }
  } catch (e) {
    console.error(e)
  }

  yield put(actions.prestitialAdFinished(ad))
}

export default function* watchAds(services) {
  yield takeEvery(actions.requestAds, adsRequest, services)
  yield takeLatest(actions.prestitialAdRequest, prestitialAdRequest, services)
}
