import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import _get from 'lodash/get'
import StorySpinner from 'src/components/StorySpinner'
import Ad from 'src/modules/Ads/components/Ad'
import Advertorial from 'src/modules/SingleStory/components/Advertorial'
import SingleStory from 'src/components/SingleStory'
import styles from 'src/modules/SingleStory/components/SingleStoryPage/SingleStoryPage.module.sass'
import commonStyles from 'src/styles/common.module.sass'
import imgFakeArticle from 'src/images/singleStoryFake.png'
import { treeToArray } from 'src/utils'
import { siteName } from 'src/utils/oneCodeBase'
import classNames from 'classnames/bind'
import { sponsoredHeadlines } from 'src/siteConfig/assets'
import { SessionContext } from 'src/modules/Session/context'
import { useDispatch, useSelector } from 'react-redux'
import { getPendingForInsertion } from 'src/modules/MyInterests/selector'
import { actions as paywallActions, getType } from 'src/modules/Paywall/slice'

import useSingleStory from 'src/hooks/useSingleStory'

const cx = classNames.bind(commonStyles)

const SingleStoryPage = props => {
  const dispatch = useDispatch()
  const { error, setStoryTitle, simplePage = false, match, item } = props
  const { disabledTags, story, loading } = useSingleStory()
  const pendingForInsertion = useSelector(getPendingForInsertion())
  const sessionContext = useContext(SessionContext)
  const component = item?.component || null

  const singleStoryLeaderboardAd = useSelector(
    state => state?.adsReducer?.singleStoryLeaderboard2 || null
  )

  const paywallType = useSelector(getType)
  useEffect(() => {
    return () => {
      if (paywallType !== 'ai_paywall' || paywallType !== 'free_feature') {
        dispatch(paywallActions.removePaywall())
      }
    }
  }, [match?.params?.id])

  const getUserTags = () => {
    const { checked: sessionReady, user } = sessionContext
    if (
      !['single_story_layout', 'single_story', 'reference_article'].includes(
        component
      )
    )
      return {}
    if (!sessionReady) return []

    const tags = treeToArray(
      _get(user, 'interests.stated', {}),
      (category, tag) => ({
        category,
        tag
      })
    )
    return tags
  }

  const { user, authenticated } = sessionContext

  const {
    interests = { visible: true, clickable: true },
    showSocialLinks = true,
    tags = { visible: true, clickable: true },
    showDividers = true,
    showRef = true,
    fullImage = false,
    showAd = true,
    showActionBar = false,
    headerFlex = 'column',
    showUpdated = false
  } = item

  const ad =
    !singleStoryLeaderboardAd || !!singleStoryLeaderboardAd.emptyContent ? (
      <Advertorial
        key="advertorial"
        to="/my-account/newsletters"
        body={sponsoredHeadlines?.singleStorySponsoredHeadline ?? ''}
        sponsor={`FirstWord ${siteName}`}
      />
    ) : (
      <Ad
        {...singleStoryLeaderboardAd}
        className={cx(styles.ad, styles.midAd)}
        key="story_page_advertorial"
      />
    )

  if (loading || !story) {
    return (
      <div
        style={{
          backgroundImage: `url('${imgFakeArticle}')`
        }}
        className={styles.fakeContent}
      >
        <StorySpinner />
      </div>
    )
  }

  if (error) {
    return (
      <div className={commonStyles.storyError}>
        Error loading the article: {error}
      </div>
    )
  }

  return (
    <SingleStory
      key={`single-story-${story.id}`}
      title={story.title}
      source={story.source}
      published={story.published}
      updated={story.updated}
      author={story.author}
      image={story.image}
      tags={story.tags}
      congress={story.congress}
      teaser={story.teaser}
      disabledTags={[...pendingForInsertion, ...disabledTags, ...getUserTags()]}
      isLimited
      type={story.type}
      typeTo={`/river/type/${story.type}`}
      ad={ad}
      body={story.body}
      setStoryTitle={setStoryTitle}
      interests={interests}
      showSocialLinks={showSocialLinks}
      storyTags={tags}
      id={story.id}
      user={user}
      authenticated={authenticated}
      simplePage={simplePage}
      showDividers={showDividers}
      showRef={showRef}
      showAd={showAd}
      fullImage={fullImage}
      allTags={story.allTags}
      reportSettings={story?.reportSettings}
      showActionBar={showActionBar}
      headerFlex={headerFlex}
      showUpdated={showUpdated}
      likes={story.likes}
      likedByProfile={story.likedByProfile}
      isPaid={story.flags?.isPaid}
    />
  )
}

SingleStoryPage.propTypes = {
  error: PropTypes.string,
  setStoryTitle: PropTypes.func,
  simplePage: PropTypes.bool,
  match: PropTypes.object,
  item: PropTypes.object
}

export default SingleStoryPage
