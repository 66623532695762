import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Snackbar from 'src/components/ui/Snackbar'
import styles from './Notifications.module.sass'

const Notifications = ({
  notifications = [],
  dismissSnackbar = () => {},
  children
}) => (
  <Fragment>
    {notifications
      .reduce((acc, n) => [n, ...acc], []) // Reverse without mutation
      .filter((n, i) => i === 0) // Select only the first item
      .map(n => (
        <Snackbar
          key={n.uid}
          dismissal={n.duration}
          onDismiss={() => dismissSnackbar(n.uid)}
          className={styles.notificationContainer}
        >
          {n.message}
        </Snackbar>
      ))}
    {children}
  </Fragment>
)

Notifications.propTypes = {
  notifications: PropTypes.array,
  dismissSnackbar: PropTypes.func,
  children: PropTypes.node
}

export default Notifications
