import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  notices: [],
  loading: false,
  error: null
}

const noticeSlice = createSlice({
  name: 'noticeReducer',
  initialState,
  reducers: {
    requestNotices: state => {
      state.loading = true
    },
    successNotices: (state, { payload }) => {
      state.notices = [...state.notices, ...payload]
      state.loading = false
      state.error = null
    },
    errorNotices: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    dismissNotice: (state, { payload: { id } }) => {
      state.notices = state.notices.filter(n => n.notification_id !== id)
      state.loading = false
      state.error = null
    }
  }
})

export const { actions, reducer } = noticeSlice

// Actions
export const { requestNotices, successNotices, errorNotices, dismissNotice } =
  actions

export default reducer
