import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Typography from 'src/components/ui/Typography'
import Link from 'src/components/Link'
import styles from './LinkedSubMenu.module.sass'
import cx from 'classnames'

const LinkedSubMenu = ({ title, to, children }) => {
  return (
    <Fragment>
      {!to ? (
        <Typography className={styles.simpleTitle}>{title}</Typography>
      ) : (
        <Typography className={cx(styles.simpleTitle, styles.linkTitle)}>
          <Link to={to}>{title}</Link>
        </Typography>
      )}
      <ul className={cx(styles.subItemsList)}>{children}</ul>
    </Fragment>
  )
}

LinkedSubMenu.propTypes = {
  title: PropTypes.string,
  to: PropTypes.string,
  children: PropTypes.node
}

export default LinkedSubMenu
