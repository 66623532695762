import { useDispatch, useSelector } from 'react-redux'

import { actions as chatActions } from '../ChatSlice'
import {
  getActiveModal,
  getFetching,
  getFormState,
  getMessages,
  getActionHandler,
  getCurrentPrompt,
  getLinkedPromptId,
  getIsTyping,
  getUrlRequested,
  getPromptNum,
  getShowInterests,
  getTags
} from '../ChatSelector'

const useChat = () => {
  // Hooks
  const dispatch = useDispatch()
  // Selectors
  const activeModal = useSelector(getActiveModal())
  const isFetching = useSelector(getFetching())
  const formState = useSelector(getFormState())
  const messages = useSelector(getMessages())
  const isTyping = useSelector(getIsTyping())
  const actionHandler = useSelector(getActionHandler())
  const currentPrompt = useSelector(getCurrentPrompt())
  const parentPromptId = useSelector(getLinkedPromptId()) || ''
  const urlRequested = useSelector(getUrlRequested())
  const promptNum = useSelector(getPromptNum())
  const showInterests = useSelector(getShowInterests())
  const tags = useSelector(getTags())

  /**
   * Prompt Validation action
   */
  const validatePromptAccess = () =>
    dispatch(chatActions.validatePromptAccess())

  /**
   * Prompt Request action
   */
  const promptRequest = payload => dispatch(chatActions.promptRequest(payload))

  /**
   * Set Prompt Num action
   */
  const setPromptNum = promptNum =>
    dispatch(chatActions.setPromptNum(promptNum))

  /**
   * Add single message action
   */
  const addMessage = message => dispatch(chatActions.addMessage(message))

  /**
   * Add multiple messages action
   */
  const addMessages = messagesPayload =>
    dispatch(chatActions.addMessages(messagesPayload))

  /**
   * Set Form State action
   */
  const setFormState = payload => dispatch(chatActions.setFormState(payload))

  /**
   * Reset Messages state
   */
  const resetMessages = () => dispatch(chatActions.resetMessages())

  /**
   * Reset Curent prompt state
   */
  const resetCurrentPrompt = () => dispatch(chatActions.resetCurrentPrompt())

  /**
   * Reset promptNum state
   */
  const resetPromptNum = () => dispatch(chatActions.resetPromptNum())

  /**
   * Reset linked prompt state
   */
  const resetLinkedPromptId = () => dispatch(chatActions.resetLinkedPromptId())

  /**
   * Update action state
   */
  const setAction = payload => dispatch(chatActions.setAction(payload))

  /**
   * Update action state
   */
  const actionSubmit = payload => dispatch(chatActions.actionSubmit(payload))

  /**
   * Request email share
   */
  const shareEmail = payload => dispatch(chatActions.shareEmail(payload))

  /**
   * Update typing state
   */
  const setTyping = payload => dispatch(chatActions.setTyping(payload))

  /**
   * Update typing state
   */
  const setActiveModal = payload =>
    dispatch(chatActions.setActiveModal(payload))

  return {
    activeModal,
    isFetching,
    formState,
    messages,
    actionHandler,
    currentPrompt,
    parentPromptId,
    isTyping,
    urlRequested,
    promptNum,
    showInterests,
    tags,
    actionSubmit,
    addMessage,
    addMessages,
    promptRequest,
    resetMessages,
    resetCurrentPrompt,
    resetPromptNum,
    resetLinkedPromptId,
    setAction,
    setActiveModal,
    setFormState,
    setPromptNum,
    shareEmail,
    setTyping,
    validatePromptAccess
  }
}

export default useChat
