import { connect } from 'react-redux'
import { compose } from 'redux'
import { actions } from '../MobileMenu/slice'
import { actions as configActions } from '../Config/slice'
import Header from '../Header/Header'
import { withTitle } from '../../utils/hocs'

const mapDispatchToProps = dispatch => ({
  handleSlideMenu: () => dispatch(actions.toggleRightMenu()),
  handleHeaderScroll: data => dispatch(configActions.updateConfigRequest(data)),
  handleLeftSlideMenu: () => dispatch(actions.toggleLeftMenu())
})

const mapStateToProps = ({ mobileMenuReducer }) => ({
  leftMenuOpen: mobileMenuReducer.leftMenuOpen,
  rightMenuOpen: mobileMenuReducer.rightMenuOpen
})

export default compose(
  withTitle,
  connect(mapStateToProps, mapDispatchToProps)
)(Header)
