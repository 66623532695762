import { compose } from 'redux'
import { connect } from 'react-redux'
import * as flags from '../../AsyncFlags/selectors'
import { actions } from '../slice'
import MyNewsletters from '../components/MyNewsletters'

const mapStateToProps = state => ({
  subscribed: state.myNewslettersReducer.subscribed,
  newsletters: state.myNewslettersReducer.newsletters,
  pendingForDeletion: state.myNewslettersReducer.pendingForDeletion,
  pendingForInsertion: state.myNewslettersReducer.pendingForInsertion,
  loadingNewsletters: flags.isBusy('listNewsletters')(state),
  loadingMyNewsletters: flags.isBusy('listSubscribedNewsletters')(state),
  newslettersConfig:
    state?.configReducer?.my_account_settings?.data?.my_newsletters
})

const mapDispatchToProps = dispatch => ({
  requestListNewsletters: () => dispatch(actions.requestListNewsletters()),
  requestListMyNewsletters: () =>
    dispatch(actions.requestListSubscribedNewsletters()),
  requestDeleteNewsletter: newsletter =>
    dispatch(actions.requestDeleteNewsletter(newsletter)),
  requestInsertNewsletter: newsletter =>
    dispatch(actions.requestInsertNewsletter(newsletter))
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  MyNewsletters
)
