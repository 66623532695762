import useMediaSize from 'src/hooks/useMediaSize'
import { licenseTypes } from 'src/services/FW5ML/parsers/UserParser'

function useViewProps(props) {
  const sessionContext = props?.sessionContext || {}
  const mediaSize = useMediaSize({
    mediaQueries: {
      small: '(max-width: 550px)',
      medium: '(max-width: 960px)'
    }
  })

  const handleViewMoreClick = section => {
    const { Piwik } = props.services
    // Space at the end becomes "-" via cleanParam in Piwik/index
    const viewMorePrefix = 'View More '
    Piwik.track('home', 'button-click', `${viewMorePrefix}${section}`)
  }

  const getArticleLink = story => `/story/${story.id}`

  const linkIfPlus = link => {
    const { checked: sessionReady, authenticated, user } = sessionContext

    return sessionReady &&
      authenticated &&
      user.license === licenseTypes.LICENSE_PLUS
      ? link
      : '/?paywall=plus_article'
  }

  const encodeArgs = args => {
    const { ContentRiverBuilder } = props.services
    return ContentRiverBuilder.encodeArgs(args)
  }

  return {
    getArticleLink,
    encodeArgs,
    mediaSize,
    handleViewMoreClick,
    linkIfPlus
  }
}

export default useViewProps
