import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

import FormInput from 'src/components/FormInput'
import Spinner from 'src/components/ui/Spinner/Spinner'

import { actions as historyActions } from '../HistorySlice'
import { getDeletingId, getIsDeleting } from '../HistorySelector'
import { actions as chatPageActions } from 'src/modules/ChatPage/ChatPageSlice'
import { CheckIcon, DeleteIcon, EditIcon } from 'src/static/icons'

/**
 * History Container
 * theme path: History.styleOverrides.itemContainer
 */
const HistoryItemContainer = styled(Box, {
  name: 'History',
  label: 'historyItemContainer',
  overridesResolver: (props, styles) => [styles.itemContainer],
  shouldForwardProp: prop => !['nightMode', 'highlighted'].includes(prop)
})(({ theme, highlighted, nightMode }) => ({
  borderRadius: highlighted && '6px',
  backgroundColor: theme.palette.history?.highlightedNightMode
}))

/**
 * History Item Form Container
 * theme path: History.styleOverrides.itemFormContainer
 */
const HistoryFormContainer = styled(Box, {
  name: 'History',
  label: 'historyItemFormContainer',
  overridesResolver: (props, styles) => [styles.itemFormContainer]
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingRright: '4px',
  paddingLeft: '32px',
  height: '32px',
  margin: '2px 0px'
}))

/**
 * History Title Input
 * theme path: History.styleOverrides.inputContainer
 */
const HistoryTitleInput = styled(FormInput, {
  name: 'History',
  label: 'historyInput',
  overridesResolver: (props, styles) => [styles.inputContainer]
})(({ theme }) => ({
  flex: '1',
  border: '0',
  marginRight: '10px',
  input: {
    color: `${theme.palette.history?.inputColor} !important`,
    fontSize: '11px',
    padding: '0 !important'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderWidth: '0 !important',
    boxShadow: 'none !important'
  },
  '&.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '0 !important',
      boxShadow: 'none !important'
    }
  }
}))

/**
 * History Title
 * theme path: History.styleOverrides.titleElement
 */
const HistoryTitle = styled(Typography, {
  name: 'History',
  label: 'titleElement',
  overridesResolver: (props, styles) => [styles.titleElement],
  shouldForwardProp: prop => !['nightMode'].includes(prop)
})(({ theme, nightMode }) => ({
  flex: 1,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginRight: '10px',
  cursor: 'pointer',
  fontSize: '11px',
  color: theme.palette.history.inputColor
}))

/**
 * History Icon Button
 * theme path: History.styleOverrides.iconButton
 */
const CustomIconButton = ({ icon, ...props }) => {
  const Icon = styled(IconButton, {
    name: 'History',
    label: 'historyIconButton',
    overridesResolver: (props, styles) => [styles.iconButton]
  })(() => ({
    width: '30px',
    height: '30px',
    margin: 'auto 3px'
  }))
  return <Icon {...props} />
}
CustomIconButton.propTypes = {
  /**
   * Icon component to be rendered
   */
  icon: PropTypes.func
}

/**
 * History Icon
 * theme path: History.styleOverrides.customIcon
 */
const CustomIcon = ({ icon, ...props }) => {
  const Icon = styled(icon, {
    name: 'History',
    label: 'historyIcon',
    overridesResolver: (props, styles) => [styles.customIcon],
    shouldForwardProp: prop => prop !== 'nightMode'
  })(({ theme, nightMode }) => {
    const { icon, iconNightMode, iconHover, iconNightModeHover } =
      theme.palette.history || {}
    return {
      fontSize: '20px',
      fill: nightMode ? iconNightMode : icon,
      'g>g': {
        fill: nightMode ? iconNightMode : icon
      },
      transition: '.3s',
      '&:hover': {
        fill: nightMode ? iconNightModeHover : iconHover,
        'g>g': {
          fill: nightMode ? iconNightModeHover : iconHover
        }
      }
    }
  })
  return <Icon {...props} />
}
CustomIcon.propTypes = {
  /**
   * Icon component to be rendered
   */
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
}

/**
 * Spinner Container
 * theme path: History.styleOverrides.spinnerContainer
 */
const SpinnerContainer = styled(Box, {
  name: 'History',
  label: 'historySpinnerContainer',
  overridesResolver: (props, styles) => [styles.spinnerContainer]
})(({ theme }) => ({
  width: '30px',
  height: '20px',
  margin: '0.25em'
}))

const HistoryItem = ({ item, nightMode, selected = false }) => {
  const dispatch = useDispatch()
  const isDeleting = useSelector(getIsDeleting())
  const deletingId = useSelector(getDeletingId())
  const [isEditing, setIsEditing] = useState(false)
  const { id, caption, createdDate } = item
  // tracking on view of answer
  const { t } = useTranslation()

  const initialValues = { historyPrompt: caption }

  // Form hook
  const {
    control,
    handleSubmit: handleFormSubmit,
    getValues,
    setValue
  } = useForm({
    mode: 'onTouched',
    defaultValues: initialValues
  })

  const values = getValues()

  const handleClick = () => {
    dispatch(historyActions.requestPromptHistory({ promptId: id }))
    dispatch(historyActions.setSelectedThread({ id }))
    dispatch(chatPageActions.displayContainer({ container: 'chat' }))
  }
  const handleEdit = () => {
    setIsEditing(true)
  }

  const onEditConfirm = ({ historyPrompt }) => {
    if (historyPrompt.trim()) {
      dispatch(historyActions.requestTitleChange({ id, title: historyPrompt }))
      setValue('historyPrompt', historyPrompt.trim())
      setIsEditing(false)
    }
  }
  const onEditCancel = () => {
    setValue('historyPrompt', caption)
    setIsEditing(false)
  }

  const handleRemove = (id, date) => {
    dispatch(historyActions.deleteHistory({ id, date, t }))
    // tracking pencil del button click
  }
  return (
    <HistoryItemContainer
      data-testid="historyItemContainer"
      highlighted={selected}
      nightMode={nightMode}
    >
      <form autoComplete="off" onSubmit={handleFormSubmit(onEditConfirm)}>
        {isEditing ? (
          <HistoryFormContainer>
            <HistoryTitleInput
              name="historyPrompt"
              control={control}
              inputProps={{
                'data-testid': 'historyItem-input',
                autoFocus: true
              }}
            />
            <CustomIconButton
              data-testid="submitButton"
              onClick={handleFormSubmit(onEditConfirm)}
            >
              <CustomIcon icon={CheckIcon} nightMode={nightMode} />
            </CustomIconButton>
            <CustomIconButton data-testid="cancelButton" onClick={onEditCancel}>
              <CustomIcon icon={CloseIcon} nightMode={nightMode} />
            </CustomIconButton>
          </HistoryFormContainer>
        ) : (
          <HistoryFormContainer>
            <HistoryTitle data-testid="historyTitle" onClick={handleClick}>
              {values.historyPrompt}
            </HistoryTitle>
            <CustomIconButton data-testid="editIconButton" onClick={handleEdit}>
              <CustomIcon
                icon={EditIcon}
                nightMode={nightMode}
                sx={{ fontSize: '16px' }}
              />
            </CustomIconButton>
            {isDeleting && deletingId === id ? (
              <SpinnerContainer data-testid="loadingSpinner">
                <Spinner
                  fillColor="transparent"
                  strokeColor="#0164b0"
                  size={20}
                />
              </SpinnerContainer>
            ) : (
              <CustomIconButton
                data-testid="removeItem"
                onClick={() => handleRemove(id, createdDate)}
              >
                <CustomIcon
                  icon={DeleteIcon}
                  nightMode={nightMode}
                  sx={{ fontSize: '20px' }}
                />
              </CustomIconButton>
            )}
          </HistoryFormContainer>
        )}
      </form>
    </HistoryItemContainer>
  )
}

HistoryItem.propTypes = {
  item: PropTypes.object,
  nightMode: PropTypes.bool,
  selected: PropTypes.bool
}
export default HistoryItem
