import React from 'react'
import PropTypes from 'prop-types'
import styles from './ToggleLabel.module.sass'
import Tag from 'src/components/Tag'
import Typography from 'src/components/ui/Typography/index'

const ToggleLabel = ({
  checked,
  name,
  id,
  frequency,
  type = 'free',
  disabled = false,
  onToggleChange = () => {}
}) => {
  return (
    <div className={styles.toggleContainer}>
      <div className={styles.toggle}>
        <input
          checked={checked}
          disabled={disabled}
          value={id}
          type="checkbox"
          id={id}
          onChange={e =>
            onToggleChange({ checked: e.target.checked, id, type, disabled })
          }
        />
        <label htmlFor={id} />
      </div>
      <div className={styles.text}>
        <div>
          {name && (
            <Typography
              className={frequency ? styles.campName : styles.campName1}
            >
              {name}
            </Typography>
          )}
          {type === 'plus' && <Tag className={styles.plus} label="PLUS" />}
        </div>
        {frequency && (
          <Typography className={styles.frequency}>{frequency}</Typography>
        )}
      </div>
    </div>
  )
}

ToggleLabel.propTypes = {
  checked: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
  frequency: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onToggleChange: PropTypes.func
}

export default ToggleLabel
