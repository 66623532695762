import { compose } from 'redux'
import { connect } from 'react-redux'
import MediaEventWrapper from '../components/MediaEventWrapper'

const mapDispatchToProps = dispatch => ({})

const mapStateToProps = state => ({
  ads: state.adsReducer
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  MediaEventWrapper
)
