import { createSlice } from '@reduxjs/toolkit'
import _mergeWith from 'lodash/mergeWith'
import _uniq from 'lodash/uniq'

export const buildRiverObject = ({
  results = [],
  batchCount = 0,
  count = 0,
  page = 0,
  aggs = {},
  filter = {},
  sponsoredHeadlines = [],
  ignoreOnMount = false,
  loading = false,
  loadingMore = false,
  showMedicalAbstracts,
  featuredResults = [],
  loadingFeaturedContent = false,
  excludeIds = [],
  tagFound = false,
  tagForCategory = {}
} = {}) => ({
  loading,
  loadingMore,
  results,
  batchCount,
  count,
  page,
  aggs,
  filter,
  sponsoredHeadlines,
  showMedicalAbstracts,
  ignoreOnMount, // Skip componentDidMount triggers
  featuredResults,
  loadingFeaturedContent,
  excludeIds,
  tagFound,
  tagOptions: [],
  loadingTags: false,
  tagForCategory
})

const initialState = {
  myfwTabValue: 'all'
}

const riverSlice = createSlice({
  name: 'contentRiverReducer',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      const { loading, riverId } = payload
      if (!state[riverId]) {
        state[riverId] = {}
      }
      state[riverId].loading = loading
    },
    setSearch: (state, { payload }) => {
      const { riverId, searchBody, isSavedSearch = false } = payload
      searchBody.from = 0
      state.isSavedSearch = isSavedSearch
      state[riverId] = {
        ...buildRiverObject({ filter: searchBody, loading: true })
      }
    },
    mergeSearch: (state, { payload }) => {
      const { riverId, searchBody, replaceFiltersCategory = '' } = payload

      if (!state[riverId]) {
        state[riverId] = {}
      }

      let filter = _mergeWith(
        {},
        state[riverId].filter,
        searchBody,
        (obj, src, key) => {
          if (key === 'publication_date') return src
          if (Array.isArray(obj)) return _uniq(obj.concat(src))
        }
      )
      if (filter.filters[replaceFiltersCategory]) {
        filter.filters[replaceFiltersCategory] =
          searchBody.filters[replaceFiltersCategory]
      }
      delete filter.from
      state[riverId].loading = true
      state[riverId].filter = filter
    },
    removeSearch: (state, { payload }) => {
      const { riverId, searchBody } = payload

      if (!state[riverId]) {
        state[riverId] = {}
      }

      const currentFilter = state[riverId].filter || {}
      let filter = {}
      const removedKeys = Object.keys(searchBody)
      for (let key in currentFilter) {
        if (!removedKeys.includes(key)) {
          filter[key] = currentFilter[key]
        }
      }
      delete filter.from
      state[riverId].loading = true
      state[riverId].filter = filter
      state[riverId].batchCount = 0
    },
    requestSearch: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'request',
          asyncKey: 'contentRiver'
        }
      }),
      reducer: () => {}
    },
    successSearch: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'success',
          asyncKey: 'contentRiver'
        }
      }),
      reducer: (state, { payload }) => {
        const { riverId, data, skipAggs = false } = payload
        const { results = [], count = 0, aggs = {}, tagFound = false } = data

        if (!state[riverId]) {
          state[riverId] = {}
        }

        const finalAggs = skipAggs ? state[riverId].aggs : aggs
        state.isSavedSearch = false
        state[riverId].loading = false
        state[riverId].loadingMore = false
        state[riverId].results = results
        state[riverId].batchCount = results.length
        state[riverId].count = count
        state[riverId].ignoreOnMount = false
        state[riverId].tagFound = tagFound
        state[riverId].aggs = finalAggs
      }
    },
    errorSearch: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'error',
          asyncKey: 'contentRiver'
        }
      }),
      reducer: () => {}
    },
    clearSearch: () => {
      state = initialState
    },
    requestLoadMore: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'request',
          asyncKey: 'contentRiverLoadMore'
        }
      }),
      reducer: (state, { payload }) => {
        const { riverId } = payload

        if (!state[riverId]) {
          state[riverId] = {}
        }

        const from = (state[riverId]?.filter?.from || 0) + 10
        const filter = { ...state[riverId].filter, from }
        state[riverId].loadingMore = true
        state[riverId].filter = filter
      }
    },
    successLoadMore: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'success',
          asyncKey: 'contentRiverLoadMore'
        }
      }),
      reducer: (state, { payload }) => {
        const { riverId, data } = payload
        const { results = [] } = data

        if (!state[riverId]) {
          state[riverId] = {}
        }

        const from = state[riverId]?.filter?.from || 10
        state[riverId].loadingMore = false
        state[riverId].results = [...state[riverId].results, ...results]
        state[riverId].batchCount = results.length
        state[riverId].page = from / 10
      }
    },
    errorLoadMore: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'error',
          asyncKey: 'contentRiverLoadMore'
        }
      }),
      reducer: () => {}
    },
    removeFilterWithoutSearch: (state, { payload }) => {
      const {
        riverId,
        filter: { field: fieldToRemove, value: valueToRemove },
        defaultFilters = { filters: {} }
      } = payload

      if (!state[riverId]) {
        state[riverId] = {}
      }

      const { filters: currentFilters } = state[riverId].filter
      const updatedFilters = Object.keys(currentFilters).reduce((acc, key) => {
        const value = currentFilters[key]
        if (fieldToRemove !== key) return { ...acc, [key]: value }
        if (Array.isArray(value) && value.indexOf(valueToRemove) > -1) {
          return {
            ...acc,
            [key]: value.filter(val => val !== valueToRemove)
          }
        } else if (typeof value === 'string' && value === valueToRemove) {
          return { ...acc }
        }
        return { ...acc, [key]: value }
      }, {})
      delete updatedFilters.from
      let newFilters = {
        ...state[riverId].filter,
        filters: {
          ...updatedFilters,
          ...defaultFilters.filters
        }
      }
      state[riverId].filter = newFilters
    },
    removeFilter: (state, { payload }) => {
      const {
        riverId,
        filter: { field: fieldToRemove, value: valueToRemove },
        defaultFilters = { filters: {} }
      } = payload

      if (!state[riverId]) {
        state[riverId] = {}
      }

      const { filters: currentFilters } = state[riverId].filter
      const updatedFilters = Object.keys(currentFilters).reduce((acc, key) => {
        const value = currentFilters[key]
        if (fieldToRemove !== key) return { ...acc, [key]: value }
        if (Array.isArray(value) && value.indexOf(valueToRemove) > -1) {
          return {
            ...acc,
            [key]: value.filter(val => val !== valueToRemove)
          }
        } else if (typeof value === 'string' && value === valueToRemove) {
          return { ...acc }
        }
        return { ...acc, [key]: value }
      }, {})
      delete updatedFilters.from
      let newFilters = {
        ...state[riverId].filter,
        filters: {
          ...updatedFilters,
          ...defaultFilters.filters
        },
        from: 0
      }
      state[riverId].filter = newFilters
    },
    setSorting: (state, { payload }) => {
      const { riverId, sort } = payload

      if (!state[riverId]) {
        state[riverId] = {}
      }

      state[riverId].page = 0
      state[riverId].filter.sort = sort
      state[riverId].from = 0
    },
    appendSponsoredHeadlines: (state, { payload }) => {
      const { riverId, sponsoredHeadlines } = payload

      if (!state[riverId]) {
        state[riverId] = {}
      }

      state[riverId].sponsoredHeadlines = [
        ...(state[riverId]?.sponsoredHeadlines
          ? state[riverId].sponsoredHeadlines
          : []),
        ...sponsoredHeadlines
      ]
    },
    setMedicalAbstracts: (state, { payload: { riverId, value } }) => {
      if (!state[riverId]) {
        state[riverId] = {}
      }

      state[riverId].showMedicalAbstracts = value
    },
    setIgnoreOnMount: (state, { payload: ignoreOnMount }) => {
      state.ignoreOnMount = ignoreOnMount
    },
    setMyfwTab: (state, { payload: myfwTabValue }) => {
      state.myfwTabValue = myfwTabValue
    },
    requestFeaturedContent: (state, { payload }) => {
      const { riverId, loading = true } = payload

      if (!state[riverId]) {
        state[riverId] = {}
      }

      state[riverId].loadingFeaturedContent = loading
    },
    successFeaturedContent: (state, { payload }) => {
      const { riverId, data, excludeIds } = payload
      const { results = [] } = data

      if (!state[riverId]) {
        state[riverId] = {}
      }

      state[riverId].loadingFeaturedContent = false
      state[riverId].featuredResults = results
      state[riverId].excludeIds = excludeIds
    },
    errorFeaturedContent: () => {},
    setTagsLoading: (state, { payload }) => {
      const { loading, riverId, category } = payload

      if (!state[riverId]) {
        state[riverId] = {}
      }

      if (!state[riverId]?.tagForCategory[category]) {
        state[riverId].tagForCategory[category] = {}
      }

      state[riverId].tagForCategory[category].loadingTags = loading
    },
    successTagsSearch: (state, { payload }) => {
      const { riverId, data = [], category = '' } = payload
      state.isSavedSearch = false

      if (!state[riverId]) {
        state[riverId] = {}
      }

      if (!state[riverId]?.tagForCategory[category]) {
        state[riverId].tagForCategory[category] = {}
      }

      state[riverId].tagForCategory[category].loadingTags = false
      state[riverId].tagForCategory[category].tagOptions = data
    },
    errorTagsSearch: (state, { payload }) => {
      const { category, riverId } = payload

      if (!state[riverId]) {
        state[riverId] = {}
      }

      if (!state[riverId]?.tagForCategory[category]) {
        state[riverId].tagForCategory[category] = {}
      }

      state[riverId].tagForCategory[category].loadingTags = false
    },
    cleanTagsSearch: (state, { payload }) => {
      const { category, riverId } = payload

      if (!state[riverId]) {
        state[riverId] = {}
      }

      if (!state[riverId]?.tagForCategory[category]) {
        state[riverId].tagForCategory[category] = {}
      }

      state[riverId].tagForCategory[category].tagOptions = []
    },
    addSelectedTag: (state, { payload }) => {
      const { option, riverId, category, singleSelect } = payload

      if (!state[riverId]) {
        state[riverId] = {}
      }

      if (!state[riverId]?.tagForCategory[category]) {
        state[riverId].tagForCategory[category] = {}
      }

      const newTag = { ...option, selected: true }
      let newSelectedTagsState = []
      if (singleSelect) {
        newSelectedTagsState = [newTag]
      } else {
        const alreadySelectedTags =
          state[riverId]?.tagForCategory[category]?.selectedTags ?? []
        const isOptionAlreadySelected = alreadySelectedTags?.findIndex(
          opt => opt.value === newTag.value
        )
        if (isOptionAlreadySelected !== -1) {
          newSelectedTagsState = [...alreadySelectedTags]
        } else {
          newSelectedTagsState = [...alreadySelectedTags, newTag]
        }
      }

      state[riverId].tagForCategory[category].selectedTags =
        newSelectedTagsState
    },
    removeSelectedTag: (state, { payload }) => {
      const { option: newTag, riverId, category } = payload

      if (!state[riverId]) {
        state[riverId] = {}
      }

      if (!state[riverId]?.tagForCategory[category]) {
        state[riverId].tagForCategory[category] = {}
      }

      const alreadySelectedTags =
        state[riverId]?.tagForCategory[category]?.selectedTags ?? []
      const filteredTags = alreadySelectedTags.filter(
        tag => tag.value !== newTag.value
      )
      state[riverId].tagForCategory[category].selectedTags = filteredTags
    },
    requestTagsSearch: () => {},
    loadContentRiver: () => {},
    postErrorSearch: () => {},
    addFilter: () => {},
    selectFilter: () => {},
    replaySearch: () => {},
    redirectSearch: () => {}
  }
})

export const { actions, reducer } = riverSlice

export const {
  setLoading,
  setTagsLoading,
  setSearch,
  mergeSearch,
  removeSearch,
  requestSearch,
  successSearch,
  errorSearch,
  clearSearch,
  loadContentRiver,
  removeFilter,
  setSorting,
  requestLoadMore,
  successLoadMore,
  appendSponsoredHeadlines,
  setIgnoreOnMount,
  replaySearch,
  redirectSearch,
  addFilter,
  selectFilter,
  postErrorSearch,
  setMyfwTab,
  setMedicalAbstracts,
  requestFeaturedContent,
  successFeaturedContent,
  errorFeaturedContent,
  requestTagsSearch,
  successTagsSearch,
  errorTagsSearch,
  cleanTagsSearch,
  removeFilterWithoutSearch,
  addSelectedTag,
  removeSelectedTag,
  errorLoadMore
} = actions

export default reducer
