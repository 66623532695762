// React
import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import useViewProps from 'src/layouts/hooks/useViewProps'
import {
  getCurrentViews,
  getViewsError,
  getViewsLoading
} from 'src/modules/Views/selectors'
import ComponentLoopItemMapping from 'src/layouts/components/children/ComponentLoopItemMapping'
import * as viewComponents from './components'
import { mappedComponents } from 'src/layouts/components/children/utils'

const mapping = {
  ...mappedComponents(viewComponents)
}

const ContentViewLoopItem = props => {
  const item = props?.item || null
  const viewProps = useViewProps(props)
  const view = useSelector(getCurrentViews)[item?.name] || null
  const viewsError = useSelector(getViewsError)
  const viewsLoading = useSelector(getViewsLoading)

  return (
    <ComponentLoopItemMapping
      {...props}
      mapping={mapping}
      viewProps={viewProps}
      view={view}
      viewsError={viewsError}
      viewsLoading={viewsLoading}
    />
  )
}

ContentViewLoopItem.propTypes = {
  item: PropTypes.object
}

export default ContentViewLoopItem
