import React, { useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import PropTypes from 'prop-types'
import NoReportsFoundView from './NoReportsFoundView'
import StoryRiverView from 'src/components/StoryRiverView'
import { actions } from 'src/modules/MyReports/slice'

const ProfileReportsView = props => {
  const dispatch = useDispatch()
  const {
    loading = null,
    data,
    error
  } = useSelector(state => state.myReportsReducer.reports, shallowEqual)

  useEffect(() => {
    dispatch(actions.requestListReports({ size: props?.size }))
  }, [])

  if (loading !== false) return

  if (data?.results && data.results.length > 0) {
    return (
      <StoryRiverView
        view={{ stories: data?.results }}
        viewsError={error}
        viewsLoading={loading}
        {...props}
      />
    )
  }
  return <NoReportsFoundView {...props} />
}

ProfileReportsView.propTypes = {
  size: PropTypes.number
}

export default ProfileReportsView
