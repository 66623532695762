import { compose } from 'redux'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import withCatalogs from '../../modules/Catalogs/withCatalogs'
import RequestAccessForm from './index'
import { actions as registerActions } from '../../modules/Register/slice'
import validate from '../../modules/Register/components/RegisterForm/validate'
import * as flags from '../../modules/AsyncFlags/selectors'
import { audienceTypes } from '../../services/FW5ML/parsers/UserParser'
import { actions as ContactActions } from '../../modules/ContactUs/slice'

const mapStateToProps = state => {
  const user = state?.session?.user || {}

  return {
    initialValues: {
      first_name: user?.firstName || null,
      email: user?.email || null,
      last_name: user?.lastName || null,
      company: user?.company || null,
      country: user?.address?.country || null,
      job_title: user?.job_title || null,
      job_function: user?.job_function || null,
      phone_number: user?.phone_number || null,
      license: audienceTypes.FREE,
      register_pharma: false,
      register_healthtech: false,
      register_edge: false,
      register_reports: false
    },
    error: flags.getError('userRegistration')(state),
    busy: flags.isBusy('userRegistration')(state)
  }
}

const mapDispatchToProps = dispatch => ({
  requestUserRegistration: data =>
    dispatch(registerActions.requestUserRegistration(data)),
  requestConsultantContact: data =>
    dispatch(
      ContactActions.requestConsultantContact({
        ...data
      })
    )
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'request_access',
    validate,
    touchOnBlur: false
  }),
  withCatalogs({
    jobFunctions: 'jobFunction',
    therapy: 'therapy',
    country: 'country',
    heardAbout: 'heardAboutOptions'
  })
)(RequestAccessForm)
