import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Typography,
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Chip
} from '@mui/material'
import { Link } from 'react-router-dom'
import Logo from 'src/components/Logo'
import styles from './aiAction.module.sass'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Header from './header'
import usePiwik from 'src/hooks/usePiwik.js'

const AnonPaywall = ({
  onClose,
  paywallSubtype,
  trackingKey,
  featureText,
  headerColor,
  showRegistrationButton,
  showCounter,
  trackingType
}) => {
  const { handleTrackingWithArticleId } = usePiwik()

  const register = props => {
    const trackingAction = props?.trackingAction || 'register'
    handleTrackingWithArticleId(
      trackingType,
      'button-click',
      `${trackingKey}_${trackingAction}`
    )
    window.location.assign('/register')
  }

  return (
    <div className={styles.modalBackdrop}>
      <Card className={styles.pharmaSubscribeContainer}>
        <CardHeader
          className={styles.cardHeader}
          component={() => (
            <Header
              showRegistrationButton={showRegistrationButton}
              showCounter={showCounter}
              onClose={onClose}
              featureText={featureText}
              headerColor={headerColor}
              register={register}
              paywallSubtype={paywallSubtype}
            />
          )}
        />

        {paywallSubtype !== 'article_counter' && (
          <CardContent
            sx={{
              '@media (max-width: 680px)': {
                padding: '15px'
              }
            }}
          >
            <Box
              sx={{
                maxHeight: 'calc(100vh - 200px)',
                overflowY: 'auto',
                padding: '16px',
                '@media (max-width: 600px)': {
                  maxHeight: 'calc(100vh - 250px)'
                }
              }}
            >
              <Grid container>
                <Grid sm={1} />
                <Grid item xs={12} sm={7}>
                  <Box className={styles.chipAndTitleContainer}>
                    <Logo className={styles.logo} />

                    <Chip
                      variant="outlined"
                      label="Exclusive Content"
                      sx={{
                        borderColor: '#2d3e50ff',
                        color: '#2d3e50ff',
                        borderRadius: '8px',
                        '@media (max-width: 680px)': {
                          borderRadius: '2px',
                          height: '15px',
                          marginTop: '5px'
                        }
                      }}
                    />
                  </Box>
                  <Typography
                    variant="h6"
                    component="h2"
                    className={styles.pharmaInsightsTitle}
                  >
                    Create your free account or log in to continue reading.
                  </Typography>
                  <Typography
                    variant="body1"
                    className={styles.pharmaInsightsListItem}
                  >
                    <CheckCircleIcon className={styles.CheckCircleIcon} />
                    Gain access to free articles and newsletters.
                  </Typography>
                  <Typography
                    variant="body1"
                    className={styles.pharmaInsightsListItem}
                  >
                    <CheckCircleIcon className={styles.CheckCircleIcon} />
                    Receive our daily FirstWord Pharma Industry News newsletter
                    to stay up-to-date on top stories.
                  </Typography>
                  <Typography
                    variant="body1"
                    className={styles.pharmaInsightsListItem}
                  >
                    <CheckCircleIcon className={styles.CheckCircleIcon} />
                    Attend FirstWord’s exclusive virtual discussions with
                    industry experts.
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Box className={styles.buttonsContainer}>
                    <Button
                      variant="contained"
                      size="large"
                      sx={{
                        backgroundColor: '#ec8756ff',
                        color: 'white',
                        width: '100%',
                        alignItems: 'flex-end',
                        textTransform: 'none',
                        '@media (max-width: 680px)': {
                          padding: '15px',
                          size: 'xlarge',
                          width: '100%'
                        }
                      }}
                      className={styles.requestButton}
                      onClick={() => {
                        register({
                          trackingAction: 'register-for-a-free-account'
                        })
                      }}
                    >
                      Register for a Free Account
                    </Button>
                    <Typography className={styles.contactText}>
                      Already have a FirstWord account?{' '}
                      <Link
                        to="/sign-in"
                        rel="noopener noreferrer"
                        className={styles.contactLink}
                        onClick={() => {
                          handleTrackingWithArticleId(
                            'paywall',
                            'button-click',
                            `${trackingKey}_sign-in`
                          )
                        }}
                      >
                        Sign in
                      </Link>
                      .
                    </Typography>
                  </Box>
                </Grid>

                <Grid item sm={1} />
              </Grid>
            </Box>
          </CardContent>
        )}
      </Card>
    </div>
  )
}

AnonPaywall.propTypes = {
  onClose: PropTypes.func,
  paywallSubtype: PropTypes.string,
  trackingKey: PropTypes.string,
  featureText: PropTypes.string,
  headerColor: PropTypes.string,
  showRegistrationButton: PropTypes.bool,
  showCounter: PropTypes.bool,
  trackingType: PropTypes.string,
  trackingAction: PropTypes.string
}

export default AnonPaywall
