import { createSlice } from '@reduxjs/toolkit'
import { method } from 'lodash'

const STACK_SIZE = 5

const initialState = {
  story: null,
  notifyMeResultArticle: null,
  disabledTags: [],
  articleStack: [],
  loading: false,
  relatedUpdates: {},
  combinedLoading: false // This tracks the combined loading state for Reports
}

const singleStorySlice = createSlice({
  name: 'singleStoryReducer',
  initialState,
  reducers: {
    requestSingleStory: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'request',
          asyncKey: 'singleStory'
        }
      }),
      reducer: (state, { payload: story }) => {
        state.loading = true
        state.combinedLoading = true
        state.notifyMeResultArticle = null
        state.story = {}
        state.notifyMeResultArticle = null
        state.disabledTags = []
        state.articleStack = [...state.articleStack, story].slice(
          -1 * STACK_SIZE
        )
      }
    },
    successSingleStory: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'success',
          asyncKey: 'singleStory'
        }
      }),
      reducer: (state, { payload: story }) => {
        state.story = story
        state.loading = false
        state.notifyMeResultArticle = null
        state.disabledTags = []
      }
    },
    errorSingleStory: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'error',
          asyncKey: 'singleStory'
        }
      }),
      reducer: () => {}
    },
    cleanSingleStory: state => {
      state.story = {}
      state.notifyMeResultArticle = null
      state.disabledTags = []
    },
    disableSingleStoryTag: (state, { payload: tag }) => {
      state.notifyMeResultArticle = null
      state.disabledTags = [...state.disabledTags, tag]
    },
    enableSingleStoryTag: (state, { payload: tag }) => {
      const tags = state.disabledTags.filter(
        disabledTag => disabledTag.tag !== tag.tag
      )
      state.disabledTags = tags
      state.notifyMeResultArticle = null
    },
    requestNotifyMeResults: state => {
      state.notifyMeResultArticle = { loading: true }
    },
    successNotifyMeResults: (state, { payload: story }) => {
      // TODO: this should probably be better: no idea what notifyMeResultArticle state is
      state.notifyMeResultArticle = { ...story, loading: false }
    },
    errorNotifyMeResults: state => {
      state.notifyMeResultArticle = { loading: false }
    },
    requestRelatedUpdates: state => {
      state.relatedUpdates = { loading: true, error: null }
      state.combinedLoading = true
    },
    successRelatedUpdates: (state, { payload }) => {
      state.relatedUpdates = { data: payload, loading: false, error: null }
      state.combinedLoading = state.loading // Only set to false if single story is also done
    },
    errorRelatedUpdates: (state, { payload: error }) => {
      state.relatedUpdates = { loading: false, error }
      state.combinedLoading = false
    },
    requestFWReportTracking: () => {},
    requestNotifyMeSubscribe: () => {},
    requestSimpleNotifyMeSubscribe: () => {},
    requestFWReportTracking: () => {},
    limitedSingleStory: () => {}
  }
})

export const { actions, reducer } = singleStorySlice

export const {
  limitedSingleStory,
  requestSingleStory,
  successSingleStory,
  errorSingleStory,
  cleanSingleStory,
  disableSingleStoryTag,
  enableSingleStoryTag,
  requestFWReportTracking,
  requestNotifyMeResults,
  successNotifyMeResults,
  errorNotifyMeResults,
  requestNotifyMeSubscribe,
  requestSimpleNotifyMeSubscribe,
  requestRelatedUpdates,
  successRelatedUpdates,
  errorRelatedUpdates
} = actions

export default reducer
