import { isValidElement } from 'react'
import { Link } from 'react-router-dom'
import i18next from 'i18next'
import { phnxSiteID, phnxSiteIDv2, defaultSpecialty } from '../constants'
import { unserialize } from '../utils'
import siteConfig from '../site.config.json'
import moment from 'moment'
import queryStringParser from 'query-string'

const { configuration, sections } = siteConfig

/**
 * This provides text, date, and other utility functions for our content.
 */

/**
 * Function that formats the authors of a certain article.
 *
 * @param {obj[]} data – array of objects in the form of { LastName: "x", ForeName: "y", Initials: "z"}
 * @returns the string ready to be displayed for the article listings.
 */
const authorsFormat = data => {
  const authorList = data.map(author => {
    return ` ${author.ForeName} ${author.LastName}`
  })
  return authorList.toString().trim()
}

/**
 * Function that formats the authors of a certain article.
 *
 * @param {obj[]} data – array of objects in the form of { LastName: "x", ForeName: "y", Initials: "z"}
 * @param size
 * @returns the string ready to be displayed for the article listings.
 */
const authorsFormatSize = (data, size) => {
  try {
    const authorList = data.slice(0, size).map(author => {
      return ` ${author.ForeName} ${author.LastName}`
    })
    return authorList.toString().trim()
  } catch (e) {
    return ''
  }
}

/**
 * Function that will convert the article body's new lines to paragraph
 * @param {string} text
 * @returns an object with the body as array, the reference and the source stripped from the text
 */
const bodyFormat = text => {
  const formattedText = {
    articleText: '',
    reference: '',
    source: ''
  }
  const refRegex = /Reference(s?)\s?:\s?(.*)/gi
  const sourceRegex = /Source:(.*\s*\S*)/gi
  const sourceArray = text.split(sourceRegex)
  let textItem = sourceArray[0]
  formattedText.source = stripTags(sourceArray[1]) // Some sources have line breaks within their html tag
  const refArray = sourceArray[0].split(refRegex)
  if (refArray?.length) {
    formattedText.reference = refArray[2]
    textItem = refArray[0]
  }
  const textRegex = /[\n]+/gm
  formattedText.articleText = textItem.trim().split(textRegex)
  return formattedText
}

/**
 * Given a max Value, return random number >0
 *
 * @param min
 * @param max
 * @returns {number}
 */
function getRandomInt(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min) + min) //The maximum is exclusive and the minimum is inclusive
}

/**
 * Gets a particular value,
 * if undefined, null or error it returns a default value.
 * Use this function to get Object values safely.
 *
 * ie: const myVar = getValue(() => someObject.someProp.SomeChildProp, defaultsTo);
 *
 * @param value (any) - The value to get
 * @param defaultsTo (any) - The value to defaults to
 * @return any
 */
const getValue = (value, defaultsTo) => {
  let safeValue

  try {
    safeValue = value()
  } catch (e) {
    return defaultsTo
  }

  return isDefined(safeValue) && safeValue !== null ? safeValue : defaultsTo
}

/**
 * Gets a particular value,
 * if undefined, null or error it returns a default value.
 * Use this function to get Object values safely.
 *
 * ie: const myVar = getValue(() => someObject.someProp.SomeChildProp, defaultsTo);
 *
 * @param value (any) - The value to get
 * @param defaultsTo (any) - The value to defaults to
 * @return any
 */
const getSafeValue = (value, defaultsTo) => {
  return !isEmpty(value) && value !== null ? value : defaultsTo
}

/**
 * A function that will convert a text into a hyphenated string.
 * E.g. Resources & Practice Aids > resources-&-practice-aids
 * @param {*} text
 */
const hyphenFormat = text => {
  return stripTags(text).toLowerCase().replace(/(\s)+/g, '-')
}

/**
 * Checks if a value is defined
 *
 * @param value (any) - The value to check for
 * @return boolean
 */
const isDefined = value => {
  return typeof value !== 'undefined'
}

/**
 * Checks if a value is empty
 *
 * @param value (any) - The value to test
 * @return boolean
 */
const isEmpty = value => {
  return (
    !isDefined(value) ||
    value === '' ||
    value === false ||
    value === 0 ||
    isEmptyObject(value) ||
    value === null
  )
}

/**
 * Checks if a value is empty but allows the zero value
 *
 * @param value (any) - The value to test
 * @return boolean
 */
const isEmtptyAllowZero = value => {
  return (
    !isDefined(value) ||
    value === '' ||
    value === false ||
    isEmptyObject(value) ||
    value === null
  )
}

/**
 * Checks if obj is an empty Object
 *
 * @param obj (object) - The object to test
 * @return boolean
 */
const isEmptyObject = obj => {
  if (typeof obj != 'object') {
    return false
  }

  return getValue(() => Object.keys(obj).length, 0) <= 0
}

/**
 * Checks if value is an integer
 *
 * @param value (any) - The value to test
 * @return boolean
 */
const isInteger = value => {
  return Number.isInteger(value)
}

/**
 * A function to replace all html tags and malformed html with just the text.
 *
 * @param {*} html
 * @returns a clean string
 */
const stripTags = html => {
  return (
    typeof html === 'string' &&
    html.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '')
  )
}

/**
 * A function to get all html tags in a text.
 *
 * @param {string} html - String containing the html
 * @returns {array} Tags array
 */
const getTags = html => {
  const regex = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/gi

  return html.match(regex)
}

/**
 * A function to get the first occurrence of an array of tags in a given text
 *
 * @param {string} html - String containing the html
 * @param {array} numberOfChars - Number of chars necessary for the required number of lines
 * @param {array} tags - Array of strings that represent the tags to look up
 * @returns {array} Ordered tags array
 */
const getIndexOfSpecialCases = (html, numberOfChars, tags) => {
  const resultArray = []

  tags.forEach(tag => {
    const finalTag = `</${tag}>`
    const indexOfFinalTag = html.lastIndexOf(finalTag)
    if (
      numberOfChars > html.indexOf(`<${tag}`) &&
      numberOfChars < indexOfFinalTag
    )
      return resultArray.push(indexOfFinalTag + finalTag.length)
  })

  return resultArray.sort()
}

/**
 * A function to truncate a long text.
 *
 * @param {string} text
 * @param {int} max : number of words to truncate
 * @param {string} altEllipsis : alternative elipsis
 *
 * @returns the new string with ellipsis at the end.
 */
const truncateText = (text, max = 50, altEllipsis = '...') => {
  const textArr = text.trim().split(/\s+/)
  const ellipsis = textArr.length > max ? altEllipsis : ''
  return textArr.slice(0, max).join(' ') + ellipsis
}

/**
 * A function that converts an unserialized PHP string.
 *
 * @param {string} text
 *
 * @returns the object with the corresponding properties.
 */
const unserializeText = text => {
  return unserialize(text)
}

/**
 * A function that prepares component for listing sections
 *
 * @param {string} listing
 *
 * @returns the object with the corresponding properties.
 */
const prepareListingLinks = listing => {
  const { link: to, ...rest } = listing
  const component = to ? Link : 'div'
  const replace = component === Link && to.startsWith('?') ? true : undefined
  return { ...rest, to, component, replace }
}

/**
 * A function that provides smooth scrolling to an element
 *
 * @param element
 * @param offset
 */
const scrollToTargetWithOffset = (element, offset) => {
  const elementPosition = element.getBoundingClientRect().top
  const offsetPosition = elementPosition - offset

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth'
  })
}

/**
 * A function that provides smooth scrolling to an element
 *
 * @param {string} id - String that identifies an element in the DOM
 * @param {number} offset - Number that will be added to the scroll position
 */
const scrollToTargetByIDWithOffset = (id, offset = 0) => {
  const element = document.getElementById(id)
  const elementPosition = element.getBoundingClientRect().top
  const offsetPosition = elementPosition + window.pageYOffset - offset

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth'
  })
}
/**
 * A function that helps completing tracking URLs
 *
 * @param {string} url
 * @param {string} secure
 * @returns {string} the url modificated if needed
 */
const transformTrackingUrl = (url, secure = false) => {
  if (url.indexOf('//') === -1) {
    url = secure === true ? 'https://' + url + '/' : 'http://' + url + '/'
  }
  if (url.indexOf('.php') === -1) {
    url = url + 'mtmo.php'
  }
  return url
}
/**
 * Function that recieves a node, find the last element and applies a style to it
 * @param {node} node
 * @param {string} style
 * @param tagName
 * @returns
 */
const addStyleToUIElement = (node, style, tagName = 'u') => {
  if (!node) return
  const elements = node.getElementsByTagName(tagName)
  if (!isEmpty(elements)) {
    const element = elements[elements.length - 1]
    element.className = style
  }
}

/**
 * Stringifies a payload and ads phnxSiteID to it
 * @param {object} payload
 * @returns a string with the new object parsed
 */
const addSiteIdToPayload = (payload = {}) => {
  return JSON.stringify({ ...payload, siteId: parseInt(phnxSiteIDv2) })
}

/**
 * Convert JWT to JSON
 * @param jwt
 */
function parseJwt(jwt) {
  try {
    let base64Url = jwt.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(c => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )
    return JSON.parse(jsonPayload)
  } catch (e) {
    return undefined
  }
}

/**
 * Parse the specialty id to the specialty key
 * @param specialtyMapping
 * @param profileSpecialty
 * @param defaultSp
 * @returns
 */
function parseSpecialty(
  specialtyMapping,
  profileSpecialty,
  defaultSp = defaultSpecialty
) {
  // TODO: improve spec. parse
  let parsedSpecialty = defaultSp
  for (const element of specialtyMapping) {
    const { key, id } = element
    if (profileSpecialty === id) {
      parsedSpecialty = key
      break
    }
  }
  return parsedSpecialty
}
/**
 * Function that processes a text and add
 * @param {string} text
 * @param {boolean} truncate
 * @param {number} limit
 * @param {string} link
 * @param {boolean} useLink
 * @returns {string}
 */
function transformExcerpt(text, truncate, limit, link, useLink = true) {
  const stripHTML = stripTags(text)
  const newLink = useLink ? `...<u>${link}</u>` : ''
  const trunctated = truncateText(stripHTML, limit, '')
  const newText =
    trunctated.slice(-1) === '.'
      ? trunctated.substring(0, trunctated.length - 1)
      : trunctated
  return newText.slice(-7) === '.&nbsp;'
    ? `${newText.substring(0, newText.length - 7)}${newLink}`
    : `${newText}${newLink}`
}

/**
 * Function that processes a text and add
 * @param {string} text
 * @param {number} limit
 * @param {string} link
 * @returns {string}
 */
function transformAdExcerpt(text, limit, link) {
  const stripHTML = stripTags(text)
  const willTruncate = stripHTML.trim().split(/\s+/).length > limit
  const newLink = link ? `<u>${link}</u>` : ''
  const trunctated = willTruncate
    ? truncateText(stripHTML, limit, '')
    : stripHTML
  const newText =
    trunctated.indexOf('.') === trunctated.length - 1
      ? trunctated.substring(0, trunctated.length - 1)
      : trunctated
  return `${newText}...${newLink}`
}

/**Function that set the right format for credit articles
 * @param {string} topRightText
 */
const creditFormatHelper = topRightText => {
  if (isEmpty(topRightText)) {
    return ''
  }
  let number = topRightText.split(' ')[0]
  let lettersToUpperCase = topRightText
    .split(' ')
    .splice(1)
    .join(' ')
    .toUpperCase()

  const regToCheckNoSpaces = /[/+](?=[A-Z])/

  if (regToCheckNoSpaces.test(lettersToUpperCase)) {
    lettersToUpperCase = lettersToUpperCase.split('/').join(' / ')
  }
  const regToCheckNoDotOrDash = !/[.]/.test(number) && !/-/.test(number)
  const regToCheckNumAfterZero = /0+(?=[1-9])/

  if (regToCheckNoDotOrDash) {
    number = `${number}.0`
  }

  const replaceDashWithDotNum = `${number} ${lettersToUpperCase}`.replace(
    /-/,
    '.'
  )

  let credit = replaceDashWithDotNum

  const regToCheckMultipleZerosAfterDotNum = /.[1-9](?=0+)/.test(credit)
  const regToCheckMultipleZerosAfterDot = /.(?=0+)/

  if (regToCheckMultipleZerosAfterDotNum) {
    let numbersAfterDot = credit.split('.')[1]
    credit = `${credit
      .split('.')[0]
      .replace(/0+/, '0')}.${numbersAfterDot.replace(/0/g, '')}`
  }

  if (regToCheckNumAfterZero.test(credit.split('.')[0])) {
    credit = `${credit.split('.')[0].replace(/0+/, '')}.${credit.split('.')[1]}`
  }

  if (regToCheckMultipleZerosAfterDot.test(credit)) {
    credit = `${credit.split('.')[0]}.${credit
      .split('.')[1]
      .replace(/0+/, '0')}`
  }

  if (!/[A-Z]/.test(credit)) {
    credit = credit.replace(' ', '')
  }

  return credit
}

/**
 * Remove all special characters but numbers and dash "-"
 * @param {string} iconReference
 */
const removeSpecialCharacters = iconReference => {
  const specialChar = /['´`]/gi
  return iconReference
    .replace(specialChar, '')
    .replace(/[|]/g, '-')
    .replace(/ /g, '-')
    .toLowerCase()
}

/**
 * Function that swaps or weitches elements positions
 * @param {number} from - index of the element that needs to me moved
 * @param {number} to - new index of the element that needs to be moved
 * @param {number} array - array with the elements
 * @returns {Array} New arrey with the swapped elements
 */
function switchElements(from, to, array) {
  if (
    typeof from === 'undefined' ||
    typeof to === 'undefined' ||
    isEmpty(array)
  )
    return array

  const newArray = [...array]

  const item = newArray.splice(from, 1)[0]
  newArray.splice(to, 0, item)

  return newArray
}

/**
 * Get the string between two specific strings inside a string
 * @param {string} queryString - text to look for the value
 * @param {string} tokenStart - start of the value
 * @param {string} tokenEnd - end of the value
 * @returns
 */
function findToken(queryString, tokenStart, tokenEnd) {
  const tokenRegex = new RegExp(`${tokenStart}(.*?)${tokenEnd}`)
  return queryString.match(tokenRegex)?.[1]
}

/**
 * SupportBCP code langs for i18n
 * @param langCode
 * @returns {string|string|*}
 */
function supportBCP(langCode) {
  try {
    const supportBCP = langCode?.split('-')
    if (isEmpty(supportBCP[1])) {
      return langCode
    } else {
      langCode = `${supportBCP[0]}-${supportBCP[1].toUpperCase()}`
      // support i18n traditional chinese
      return langCode?.includes('zh-HANS') ? 'zh-CN' : langCode
    }
  } catch (e) {
    return ''
  }
}

/**
 * Filters proper links for each role
 * @param isAuth
 * @param isLimited
 * @param object
 * @returns {*}
 */
const getRoleBasedLinks = (isAuth, isLimited, object) => {
  if (isLimited) return object.links.filter(item => item.limited === true)
  else if (isAuth) return object.links.filter(item => item.auth === true)
  else return object.links.filter(item => item.anon === true)
}

/**
 * Attempt to translate specialty against profileSpecialtyList
 * @param specialty
 * @param i18n react-i18next - useTranslation
 * @param t react-i18next - useTranslation
 * @returns string
 */
const translateSpecialty = (specialty, i18n, t) =>
  isEmpty(specialty)
    ? defaultSpecialty
    : i18n.exists(`editProfileSpecialtyList.${specialty}`)
    ? t(`editProfileSpecialtyList.${specialty}`)
    : specialty

/**
 * Replace the token for the given value
 * @param {string} text - text to replace for the new value
 * @param {*} value - new value
 * @param {string} token - token to identify the value to replace by regex
 *
 * @returns text with the value replaced
 */
function replaceToken(text, value, token) {
  const tokenRegex = new RegExp(`${token}`)
  const emptyTokenRegex = new RegExp(`[,]?${token}`, 'gi')
  return isEmpty(value)
    ? text.replace(emptyTokenRegex, '""')
    : text.replace(tokenRegex, value)
}

/**
 * Function that looks into an object of specialties and returns
 * its value
 * @param {string} specialty
 * @param {object} specialtySynonyms
 * @returns {array} value of the specialty synonym(s)
 */
function getSpecialtySynonyms(specialty = '', specialtySynonyms = {}) {
  const concatSpec = spec => [].concat(spec)

  if (isEmpty(specialtySynonyms))
    return isEmpty(specialty) ? [] : concatSpec(specialty)
  else if (specialtySynonyms.hasOwnProperty(specialty)) {
    return concatSpec(specialtySynonyms[specialty].concat(specialty))
  } else return concatSpec(specialty)
}

/**
 * Funtion for retrieving a sibling in certain direction
 * @param {*} event - The click event
 * @param {*} siblingDirection - Is the direction of the sibling, values can be nextElementSibling or previousElementSibling
 * @param {*} tagName - Is the tag Name of the sibling that we are looking for
 * @returns - The first sibling of the specified tag name and the specified direction
 */
function getSibling(
  startElement,
  siblingDirection = 'nextElementSibling',
  tagName = 'A'
) {
  while ((startElement = startElement?.[siblingDirection]))
    if (startElement?.tagName === tagName) return startElement

  return {}
}

const isParticularElement = (element, displayNames) => {
  return (
    isValidElement(element) &&
    displayNames.indexOf(element.type.displayName) !== -1
  )
}

const getInitials = name => {
  const names = name.split(' ')
  let firstInitial
  if (names.length > 2) {
    firstInitial = names[1].charAt(0)
  }
  const lastInitial = names[names.length - 1].charAt(0)
  return `${firstInitial || ''}${lastInitial || ''}`
}

/**
 * Get the number of chars that will fill the lines
 * @param {*} width - Width of the space that will contain the chars
 * @param {*} widthConstant - Relation betweeen the font size and the width
 * @param {*} lines - Number of lines that are soposed to be filled
 * @param {*} fontSize - Size of the font that is going to be measured
 * @returns {number} - number of chars
 */
const getCharsBasedOnLines = (width, widthConstant, lines, fontSize) => {
  return Math.ceil((width * widthConstant * lines) / fontSize)
}

/**
 * Get the number of lines that a string could use
 * @param {*} fontSize - Size of the font that is going to be measured
 * @param {*} numberOfChars - Number of chars of the string
 * @param {*} width - Width of the space that will contain the chars
 * @param {*} widthConstant - Relation betweeen the font size and the width
 * @returns {number} number of lines
 */
const getLinesBasedOnChars = (
  fontSize,
  numberOfChars,
  width,
  widthConstant
) => {
  return Math.ceil((fontSize * numberOfChars) / (width, widthConstant))
}

/**
 * Function for tracking, get the ad type name
 * @param {string} type - Type from ad props
 */
const getAdType = type => {
  switch (type) {
    case 'som':
      return type
    case 'somRiver':
      return 'som-river'
    case 'iframe':
      return 'aimatch-screen1'
    default:
      return `aimatch-${type}`
  }
}

/**
 * Function to get closest threshold value.
 * @param {number} entry - Value to check
 * @param {array} thresholdValues -Values to approach
 */
const computePercentValue = (entry, thresholdValues) => {
  return Math.round(
    thresholdValues.reduce((previuos, current) => {
      if (
        Math.abs(current - entry.intersectionRatio) <
        Math.abs(previuos - entry.intersectionRatio)
      )
        return current
      else return previuos
    }) * 100
  )
}

/**
 * Function to get the last time value minus the previous value or default values
 * @param {array} times - Time values
 */
const getTimeOnAd = times => {
  if (times.length < 2) return 1
  else {
    const timeOnAd = Math.round(
      (times[times.length - 1] - times[times.length - 2]) / 1000
    )
    return timeOnAd === 0 ? 1 : timeOnAd
  }
}
/**
 *  Function for obtaining the query corresponding to the given label
 * @param {object} infiniteQuery - Object containing all the different types of rivers
 * @param {string} activeTag - Tag that is selected right now
 * @param {string} property - The property that we are looking for
 * @returns - the query corresponding to the given label
 */
function getTagProperty(
  infiniteQuery,
  activeTag = 'all',
  property = 'query',
  defaultValue = {}
) {
  if (isEmpty(infiniteQuery.rivers))
    return !isEmpty(defaultValue) ? defaultValue : infiniteQuery

  const { rivers } = infiniteQuery

  //Search inside all the rivers in the config
  if (isEmpty(rivers[activeTag]?.[property]))
    return !isEmpty(defaultValue) ? defaultValue : rivers.all[property]
  else return rivers[activeTag][property]
}

/**
 * Function to parse the specialties array to the translated text
 * @param {*} specialties
 * @param {*} listKey
 * @returns
 */
function parseSpecialties(specialties, listKey = 'editProfileSpecialtyList') {
  let parsedSpec = {}
  specialties.forEach(specObj => {
    parsedSpec[specObj.key] = i18next.t(`${listKey}.${specObj.key}`)
  })
  return parsedSpec
}

/**
 * Function to parse the professions array to the translated text
 * @param {*} professions
 * @param {*} listKey
 * @returns
 */
function parseProfessions(professions, listKey = 'editProfileProfessionList') {
  let parsedProf = {}
  Object.values(professions).forEach(key => {
    parsedProf[key] = i18next.t(`${listKey}.${key}`)
  })
  return parsedProf
}

/**
 * Function to parse the countries array to the translated text
 * @param {*} countries
 * @param {*} listKey
 * @returns
 */
function parseCountries(countries, listKey = 'editProfileCountryList') {
  const transCountry = {}
  Object.keys(countries).map(
    key => (transCountry[key] = i18next.t(`${listKey}.${key}`))
  )
  return transCountry
}

/**
 * Function to calculate the
 * @param {*} adEvery
 * @param {*} adStartPoint
 * @param {*} listingsWAds
 * @param {*} pageNumber
 * @param {*} seqIndex
 * @param {*} tag
 * @returns {object} { aimatchNumberOfAds, somNumberOfAds, totalNumberOfAds }
 */
function numberOfAdsToBeFetched({
  adEvery,
  adStartPoint,
  listingsWAds,
  pageNumber,
  seqIndex,
  srAdded,
  tag
}) {
  const {
    sequence,
    somAdsRiver: { repeat }
  } = configuration?.ads?.content?.newsRiver

  const { infiniteQuery, feedFilter } = sections.siteRiver

  //Retrieve correct query
  const { maxResults } = feedFilter
    ? getTagProperty(infiniteQuery, tag)
    : infiniteQuery

  //Get number of ads
  //Variable that we will use in the formula as the next start point
  const nextStartPoint = getNextStartPoint({
    adEvery,
    adStartPoint,
    listingsWAds,
    pageNumber
  })

  //Special case of start point 0
  const newAdEvery = nextStartPoint === 0 && adEvery > 1 ? adEvery - 1 : adEvery
  //total of ads that should be fetched
  const totalNumberOfAds = Math.floor(
    (maxResults - nextStartPoint) / newAdEvery
  )
  //Number of aimatch ads
  let aimatchNumberOfAds = totalNumberOfAds
  //Number of total som ads
  let somNumberOfAds = 0

  //If the sequence includes somRiver and it has not marked as added
  if (
    sequence.includes('somRiver') &&
    (sequence[seqIndex[tag]] === 'somRiver' ||
      sequence[seqIndex[tag] + 1] === 'somRiver') &&
    srAdded[tag] === false &&
    aimatchNumberOfAds > 1
  ) {
    if (!repeat)
      //If the somRiver shouldn't be repeated and is not added already
      //Just substract 1 as one posistion is for this type of ad
      aimatchNumberOfAds = aimatchNumberOfAds - 1
    //If it going to repeat, cut the number by half
    else aimatchNumberOfAds = Math.floor(aimatchNumberOfAds / 2)
  }

  //If sequence includes the som ad type
  if (sequence.includes('som')) {
    //Get how many types the sequence array has
    const adsPerType = Math.round(aimatchNumberOfAds / sequence.length)
    //if the next ad is an aimatch assume we will need more aimatch
    if (sequence[seqIndex[tag]] === 'aimatch')
      somNumberOfAds = aimatchNumberOfAds - adsPerType
    //if the next ad is an som assume we will need more som
    else if (sequence[seqIndex[tag]] === 'som')
      aimatchNumberOfAds = aimatchNumberOfAds - adsPerType
  }

  return {
    aimatchNumberOfAds,
    somNumberOfAds,
    totalNumberOfAds
  }
}

/**
 * Function for getting the place where the next ad should be
 * @param {number} adEvery
 * @param {number} adStartPoint
 * @param {array} listingsWAds
 * @param {number} pageNumber
 * @returns the place where the next ad should be
 */
function getNextStartPoint({
  adEvery,
  adStartPoint,
  listingsWAds,
  pageNumber
}) {
  //Get the place where the next ad should be
  if (pageNumber === 1) return adStartPoint
  else {
    //Substract from the ad cadence the place where the last ad is
    //So we will get the place where the next ad will be
    return (
      adEvery -
      //find the index of the last ad
      [...listingsWAds]
        ?.reverse()
        ?.findIndex(({ componentType }) => componentType === 'ad')
    )
  }
}

/**
 * Function to save a new viewid any time it is needed
 * @param {any} services
 * @param {any} call
 */
function* saveViewId({ services, call }) {
  const ParamsService = services('ParamsService')
  const SessionService = services('SessionService')
  const partyId = yield call([SessionService, 'getFromCache'], 'MemberID', '')
  const viewid = partyId + phnxSiteID + Date.now()
  yield call([ParamsService, 'saveToCache'], {
    viewid
  })
}

/**
 * Function to calculate the
 * @param {*} adEvery
 * @param {*} adStartPoint
 * @param {*} nextStartPoint
 * @param {*} pageNumber
 * @param {*} seqIndex
 * @param {*} tag
 * @param {*} defaultSearchQuery
 * @returns {object} { aimatchNumberOfAds, somNumberOfAds, totalNumberOfAds }
 */
function numberOfAdsCustomQuery({
  adEvery,
  adStartPoint,
  nextStartPoint,
  pageNumber,
  seqIndex,
  srAdded,
  tag,
  maxResults,
  customSequence
}) {
  const {
    sequence: defaultSequence,
    somAdsRiver: { repeat }
  } = configuration?.ads?.content?.newsRiver

  const sequence = customSequence || defaultSequence

  // const { infiniteQuery, feedFilter } = sections.siteRiver

  //Retrieve correct query

  //Get number of ads
  if (pageNumber === 1) nextStartPoint = adStartPoint

  //Special case of start point 0
  const newAdEvery = nextStartPoint === 0 && adEvery > 1 ? adEvery - 1 : adEvery
  //total of ads that should be fetched
  let totalNumberOfAds = Math.floor((maxResults - nextStartPoint) / newAdEvery)
  //Number of aimatch ads
  let aimatchNumberOfAds = totalNumberOfAds
  //Number of total som ads
  let somNumberOfAds = 0

  //If the sequence includes somRiver and it has not marked as added
  if (
    sequence.includes('somRiver') &&
    (sequence[seqIndex[tag]] === 'somRiver' ||
      sequence[seqIndex[tag] + 1] === 'somRiver') &&
    srAdded[tag] === false &&
    aimatchNumberOfAds > 1
  ) {
    if (!repeat)
      //If the somRiver shouldn't be repeated and is not added already
      //Just substract 1 as one posistion is for this type of ad
      aimatchNumberOfAds = aimatchNumberOfAds - 1
    //If it going to repeat, cut the number by half
    else aimatchNumberOfAds = Math.floor(aimatchNumberOfAds / 2)
  }

  //If sequence includes the som ad type
  if (sequence.includes('som')) {
    //Get how many types the sequence array has
    const adsPerType = Math.round(aimatchNumberOfAds / sequence.length)
    //if the next ad is an aimatch assume we will need more aimatch
    if (sequence[seqIndex[tag]] === 'aimatch')
      somNumberOfAds = aimatchNumberOfAds - adsPerType
    //if the next ad is an som assume we will need more som
    else if (sequence[seqIndex[tag]] === 'som')
      aimatchNumberOfAds = aimatchNumberOfAds - adsPerType
  }

  //Set the start point for the next batch of ads
  if (nextStartPoint === 0)
    nextStartPoint =
      maxResults - (nextStartPoint + (totalNumberOfAds - 1) * adEvery)
  else
    nextStartPoint = maxResults - (nextStartPoint + totalNumberOfAds * adEvery)

  return {
    aimatchNumberOfAds,
    somNumberOfAds,
    totalNumberOfAds,
    newStartPoint: nextStartPoint
  }
}

/**
 * Function to get the date formatted in chunks(today, yesterday, etc.)
 * @param {string} date // Must be 'DD/MM/YYYY'
 */
function getDateChunk(date) {
  return (
    date &&
    moment(date, 'DD/MM/YYYY').calendar(null, {
      sameDay: '[Today]',
      lastDay: '[Yesterday]',
      lastWeek: 'dddd',
      sameElse: 'DD/MM/YYYY'
    })
  )
}

/**
 * Function to obfuscate otp/dp tokens from url
 * @param {string} url
 */
function obfuscateUrlTokens(url) {
  if (!url) return undefined
  const { url: originalUrl, query } = queryStringParser.parseUrl(url)
  if (query.dp) query.dp = '...'
  if (query.otp) query.otp = '...'
  return queryStringParser.stringifyUrl({
    url: originalUrl,
    query
  })
}

/**
 * Function to find the profession id
 * @param {*} professions - Object filled eith the professions object
 * @param {*} profession - The desired profession
 * @returns - The desired profession id
 */
function getProfessionId(professions, profession) {
  return parseInt(
    Object.keys(professions).find(key => professions[key] === profession)
  )
}

/**
 * Function to find the userflow languageCode
 * @param {*} lang - user translation language
 * @returns - The languacode for userflow
 */
const getUserflowLanguageCode = lang => {
  const { localization } = configuration
  return localization.userflowLanguageCodes
    ? localization.userflowLanguageCodes[lang] ||
        localization.userflowLanguageCodes.default
    : undefined
}

async function hash256(string) {
  const utf8 = new TextEncoder().encode(string)
  const hashBuffer = await crypto.subtle.digest('SHA-256', utf8)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray
    .map(bytes => bytes.toString(16).padStart(2, '0'))
    .join('')
  return hashHex
}

const extractTextFromReactElement = element => {
  if (typeof element === 'string') {
    return element
  }

  if (Array.isArray(element)) {
    return element.map(extractTextFromReactElement).join(' ')
  }

  if (element.props && element.props.children) {
    return extractTextFromReactElement(element.props.children)
  }

  return ''
}

export {
  getInitials,
  isParticularElement,
  getRandomInt,
  transformExcerpt,
  transformAdExcerpt,
  authorsFormat,
  authorsFormatSize,
  prepareListingLinks,
  bodyFormat,
  scrollToTargetWithOffset,
  scrollToTargetByIDWithOffset,
  unserializeText,
  truncateText,
  stripTags,
  getTags,
  isInteger,
  isEmptyObject,
  isEmpty,
  isEmtptyAllowZero,
  isDefined,
  hyphenFormat,
  getValue,
  transformTrackingUrl,
  addStyleToUIElement,
  addSiteIdToPayload,
  parseJwt,
  parseSpecialty,
  creditFormatHelper,
  removeSpecialCharacters,
  switchElements,
  findToken,
  supportBCP,
  getRoleBasedLinks,
  translateSpecialty,
  replaceToken,
  getSpecialtySynonyms,
  getSibling,
  getSafeValue,
  getCharsBasedOnLines,
  getLinesBasedOnChars,
  getIndexOfSpecialCases,
  getAdType,
  computePercentValue,
  getTimeOnAd,
  getTagProperty,
  parseSpecialties,
  parseProfessions,
  parseCountries,
  numberOfAdsToBeFetched,
  saveViewId,
  numberOfAdsCustomQuery,
  getDateChunk,
  obfuscateUrlTokens,
  getProfessionId,
  getUserflowLanguageCode,
  hash256,
  extractTextFromReactElement
}
