import _get from 'lodash/get'
import { AD_TYPE_AIMATCH } from '../../services/Ads/constants/adTypes'

const ads = {
  // Leaderboard Ads
  leaderboardHomeAd: {
    id: 'home-top-md',
    type: AD_TYPE_AIMATCH,
    mediaType: 'image',
    area: 'home_page',
    position: 'leaderboard_1'
  },
  leaderboardStoryAd: {
    id: 'story-top-md',
    type: AD_TYPE_AIMATCH,
    mediaType: 'image',
    area: 'story_page',
    position: 'leaderboard_1',
    showForRoutes: [
      {
        path: '/story',
        exactMatch: false
      }
    ]
  },
  leaderboardRiverAd: {
    id: 'river-top-md',
    type: AD_TYPE_AIMATCH,
    mediaType: 'image',
    area: 'river_page',
    position: 'leaderboard_1',
    showForRoutes: [
      {
        path: '/river',
        exactMatch: false
      }
    ],
    pagesToExclude: ['/river/events', '/river/my-fw']
  },
  // Single story ads
  singleStoryRectangle1: {
    id: 'story-sidebar-ad',
    type: AD_TYPE_AIMATCH,
    mediaType: 'image',
    area: 'story_page',
    position: 'medium_rectangle_1',
    waitForState: state =>
      _get(state, 'singleStoryReducer.story.id', undefined),
    stateBasedConfig: state => ({
      atype: state.singleStoryReducer.story.type,
      aproduct: _get(
        state,
        'singleStoryReducer.story.categorizedTags.fw_product'
      ),
      acompany: _get(
        state,
        'singleStoryReducer.story.categorizedTags.fw_company'
      ),
      aconference: _get(
        state,
        'singleStoryReducer.story.categorizedTags.fw_conference'
      ),
      acondition: _get(
        state,
        'singleStoryReducer.story.categorizedTags.conditions'
      ),
      atherapeutic: _get(
        state,
        'singleStoryReducer.story.categorizedTags.fw_therapeutic_category'
      ),
      amarket: _get(
        state,
        'singleStoryReducer.story.categorizedTags.fw_regulatory_market'
      ),
      aregulatory: _get(
        state,
        'singleStoryReducer.story.categorizedTags.fw_regulatory_qualifier'
      ),
      astorywatch: _get(
        state,
        'singleStoryReducer.story.categorizedTags.fw_story_watch'
      ),
      abusiness: _get(
        state,
        'singleStoryReducer.story.categorizedTags.fw_job_position'
      )
    }),
    showForRoutes: [
      {
        path: '/story',
        exactMatch: false
      }
    ]
  },
  singleStoryRectangle2: {
    id: 'story-midsidebar-ad',
    type: AD_TYPE_AIMATCH,
    mediaType: 'image',
    area: 'story_page',
    position: 'medium_rectangle_2',
    waitForState: state =>
      _get(state, 'singleStoryReducer.story.id', undefined),
    stateBasedConfig: state => ({
      atype: state.singleStoryReducer.story.type,
      aproduct: _get(
        state,
        'singleStoryReducer.story.categorizedTags.fw_product'
      ),
      acompany: _get(
        state,
        'singleStoryReducer.story.categorizedTags.fw_company'
      ),
      aconference: _get(
        state,
        'singleStoryReducer.story.categorizedTags.fw_conference'
      ),
      acondition: _get(
        state,
        'singleStoryReducer.story.categorizedTags.conditions'
      ),
      atherapeutic: _get(
        state,
        'singleStoryReducer.story.categorizedTags.fw_therapeutic_category'
      ),
      amarket: _get(
        state,
        'singleStoryReducer.story.categorizedTags.fw_regulatory_market'
      ),
      aregulatory: _get(
        state,
        'singleStoryReducer.story.categorizedTags.fw_regulatory_qualifier'
      ),
      astorywatch: _get(
        state,
        'singleStoryReducer.story.categorizedTags.fw_story_watch'
      ),
      abusiness: _get(
        state,
        'singleStoryReducer.story.categorizedTags.fw_job_position'
      )
    }),
    showForRoutes: [
      {
        path: '/story',
        exactMatch: false
      }
    ]
  },
  singleStoryRectangle3: {
    id: 'story-rectangle3-ad',
    type: AD_TYPE_AIMATCH,
    mediaType: 'image',
    area: 'story_page',
    position: 'medium_rectangle_3',
    showForRoutes: [
      {
        path: '/story',
        exactMatch: false
      }
    ]
  },
  singleStoryLeaderboard2: {
    id: 'story-leaderboard2-ad',
    type: AD_TYPE_AIMATCH,
    mediaType: 'image',
    area: 'story_page',
    position: 'leaderboard_2',
    showForRoutes: [
      {
        path: '/story',
        exactMatch: false
      }
    ]
  },
  homeMediumRectangle3: {
    id: 'newsletter-sign-up-banner',
    type: AD_TYPE_AIMATCH,
    mediaType: 'image',
    area: 'home_page',
    position: 'medium_rectangle_3',
    showForRoutes: [
      {
        path: '/',
        exactMatch: true
      }
    ]
  },
  // home ads
  homeLeaderboard2: {
    id: 'home-leaderboard2-ad',
    type: AD_TYPE_AIMATCH,
    mediaType: 'image',
    area: 'home_page',
    position: 'leaderboard_2',
    showForRoutes: [
      {
        path: '/',
        exactMatch: true
      }
    ]
  },
  homeLeaderboard2Mobile: {
    id: 'home-leaderboard2-ad-mobile',
    type: AD_TYPE_AIMATCH,
    mediaType: 'image',
    area: 'home_page',
    position: 'leaderboard_2',
    showForRoutes: [
      {
        path: '/',
        exactMatch: true
      }
    ]
  },
  // Events Page ads now billboardAd
  eventsBillboardAd: {
    id: 'events-billboard-desktop',
    type: AD_TYPE_AIMATCH,
    mediaType: 'image',
    area: 'event_page',
    position: 'billboard_1',
    showForRoutes: [
      {
        path: '/river/events',
        exactMatch: true
      }
    ]
  },
  eventsBillboardMobileAd: {
    id: 'events-billboard-mobile',
    type: AD_TYPE_AIMATCH,
    mediaType: 'image',
    area: 'event_page',
    position: 'billboard_1',
    showForRoutes: [
      {
        path: '/river/events',
        exactMatch: true
      }
    ]
  },
  // River body ads
  flashAd: {
    id: 'flash-ad',
    type: AD_TYPE_AIMATCH,
    area: 'river_page',
    mediaType: 'iframe',
    position: 'leaderboard_2',
    height: '500px',
    width: '100%',
    scrolling: 'yes',
    showForRoutes: [
      {
        path: '/river',
        exactMatch: false
      }
    ],
    pagesToExclude: ['/river/events', '/river/my-fw']
  },
  riverLeaderboard2: {
    id: 'river-leaderboard2',
    type: AD_TYPE_AIMATCH,
    mediaType: 'image',
    area: 'river_page',
    position: 'leaderboard_2',
    showForRoutes: [
      {
        path: '/river',
        exactMatch: false
      }
    ],
    pagesToExclude: ['/river/events', '/river/my-fw']
  },
  riverLeaderboard3: {
    id: 'river-leaderboard3',
    type: AD_TYPE_AIMATCH,
    mediaType: 'image',
    area: 'river_page',
    position: 'leaderboard_3',
    showForRoutes: [
      {
        path: '/river',
        exactMatch: false
      }
    ],
    pagesToExclude: ['/river/events', '/river/my-fw']
  },
  riverMediumRectangle3: {
    id: 'river-ad-rectangle3',
    type: AD_TYPE_AIMATCH,
    mediaType: 'image',
    area: 'river_page',
    position: 'medium_rectangle_3',
    showForRoutes: [
      {
        path: '/river',
        exactMatch: false
      }
    ],
    pagesToExclude: ['/river/events', '/river/my-fw']
  }
}

export default ads
