import React, { useEffect } from 'react'

import { useSelector } from 'react-redux'
import MainLayout from 'src/layouts/children/MainLayout'
import SingleColumnLayout from 'src/layouts/children/SingleColumnLayout'

import styles from 'src/layouts/children/SingleStoryLayout/singlestory.module.sass'
import useScrollBlock from 'src/hooks/useScrollBlock'
import { getBlur } from 'src/modules/Paywall/slice'
import PropTypes from 'prop-types'

function SingleStoryLayout(props) {
  const [blockScroll, allowScroll] = useScrollBlock()
  const blurArticle = useSelector(getBlur)

  useEffect(() => {
    if (blurArticle) {
      blockScroll()
    }

    return () => {
      allowScroll()
    }
  }, [blurArticle])

  const componentsBySubLayout = {
    main_layout: MainLayout,
    single_column_layout: SingleColumnLayout
  }

  let Component = componentsBySubLayout[props.sub_layout]
  if (!Component) Component = MainLayout

  return (
    <div className={blurArticle ? styles.blurPage : ''}>
      <Component {...props} />
    </div>
  )
}
SingleStoryLayout.propTypes = {
  sub_layout: PropTypes.string
}

export default SingleStoryLayout
