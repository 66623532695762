import { any } from '../../utils'

export const isBusy = requestNames => state => {
  const arr = Array.isArray(requestNames) ? requestNames : [requestNames]
  const stateBranch = state.asyncFlagsReducer
  return any(arr, el => stateBranch[el] !== undefined && stateBranch[el].busy)
}

export const hasError = requestNames => state => {
  const arr = Array.isArray(requestNames) ? requestNames : [requestNames]
  const stateBranch = state.asyncFlagsReducer
  return any(
    arr,
    el => stateBranch[el] !== undefined && !!stateBranch[el].error
  )
}

export const getError = requestName => state => {
  const stateBranch = state.asyncFlagsReducer
  if (!stateBranch[requestName]) return undefined
  return stateBranch[requestName].error
}

export default requestName => ({
  isBusy: isBusy(requestName),
  hasError: hasError(requestName),
  getError: getError(requestName)
})
