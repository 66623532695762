import { compose } from 'redux'
import { connect } from 'react-redux'
import DynamicRiverBody from './components/DynamicRiverBody'
import { actions } from './slice'

const mapDispatchToProps = dispatch => ({
  getRiverContent: data => dispatch(actions.requestDynamicRiverContent(data)),
  loadMoreRiverContent: data => dispatch(actions.requestLoadMore(data))
})

const mapStateToProps = state => ({
  dynamicRivers: state.dynamicContentRiverReducer
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DynamicRiverBody
)
