import React, { Fragment } from 'react'
import RoutedLink from 'src/components/RoutedLink'
import { notificationDuration as duration } from 'src/constants'
import { takeEvery, put, call, select } from 'redux-saga/effects'
import { actions } from './slice'
import { notificationsEnqueue } from 'src/modules/Notifications/slice'
import { logPush } from 'src/modules/ContactUs/slice'
import {
  emailLoginFlow,
  nlLoginFlow,
  initializeSettings
} from 'src/modules/Bootstrapping/saga'
import { LOGIN_TOKEN, NL_TOKEN } from 'src/modules/Captcha/constants'
import { CAPTCHA_VERIFICATION_FAILED } from 'src/services/FW5ML/errorCodes'
import FW5MLError from 'src/services/FW5ML/FW5MLError'

function* requestCaptchaLogin(services, action) {
  const {
    payload: { captchaToken }
  } = action
  const Piwik = services('Piwik')

  const {
    meta: { token, type: tokenType, redirect, nlEventId, nlCampaignId }
  } = yield select(state => state.captchaReducer)

  try {
    // Auth flows
    if (tokenType === LOGIN_TOKEN) {
      // Passwordless Login Flow
      yield call(emailLoginFlow, services, token, captchaToken)
      // Initialize settings
      yield call(initializeSettings, services)
    } else if (tokenType === NL_TOKEN) {
      // NL Autologin Flow
      yield call(
        nlLoginFlow,
        services,
        token,
        captchaToken,
        {
          nlEventId,
          nlCampaignId
        },
        redirect
      )
      // Initialize settings
      yield call(initializeSettings, services)
    }
    yield put(actions.successCaptchaLogin())
  } catch (e) {
    if (e instanceof FW5MLError) {
      const code = e.getCode()
      if (code === CAPTCHA_VERIFICATION_FAILED) {
        yield put(
          notificationsEnqueue({
            message: (
              <Fragment>
                Captcha verification failed. Please try again.
              </Fragment>
            ),
            duration
          })
        )
        yield call(
          [Piwik, 'track'],
          'notification',
          'error',
          'captcha-verification-failed'
        )
        yield put(actions.errorCaptchaLogin(e.message))
        return
      }
    }
    yield put(logPush('Recaptcha', `Error while logging: ${e.message}`))
    yield put(
      notificationsEnqueue({
        message: (
          <Fragment>
            Something went wrong! <RoutedLink label="Contact us" />
          </Fragment>
        ),
        duration
      })
    )
    yield call([Piwik, 'track'], 'notification', 'error', e.message)
    yield put(actions.errorCaptchaLogin(e.message))
  }
}

export default function* watchCaptcha(services) {
  yield takeEvery(actions.requestCaptchaLogin, requestCaptchaLogin, services)
}
