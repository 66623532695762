// React
import React from 'react'

// Styling
import singleStoryStyles from 'src/modules/SingleStory/components/SingleStoryPage/SingleStoryPage.module.sass'

// Components
import StoryRiver from 'src/components/StoryRiver'
import ReferenceArticleExcerpt from 'src/components/ReferenceArticleExcerpt'
import SectionHeading from 'src/components/SectionHeading'
import SingleStory from 'src/modules/SingleStory/components/SingleStoryPage'
import { Link } from 'react-router-dom'
import NotifyUpdates from 'src/components/SingleStory/reports/children/NotifyUpdates'
import ReportRelatedUpdates from 'src/components/SingleStory/reports/ReportRelatedUpdates'

// Hooks
import useViewProps from 'src/layouts/hooks/useViewProps'

/*
  ##################################
    BEGIN COMPONENTS DECLARATION
  ##################################
*/
export const LoopItem_single_story = props => {
  return <SingleStory {...props} />
}

export const LoopItem_reference_article = props => {
  const { story = null } = props
  if (!story?.referenceArticles || story?.referenceArticles?.length <= 0)
    return <></>

  return (
    <StoryRiver
      title={
        story.referenceArticles.length > 1
          ? 'Reference Articles'
          : 'Reference Article'
      }
    >
      {story.referenceArticles.map((article, i) => (
        <ReferenceArticleExcerpt
          key={i}
          to={article.url}
          title={article.title}
          name={article.name}
        />
      ))}
    </StoryRiver>
  )
}

export const LoopItem_read_more_story_tags = props => {
  const { story = null, item = { title: 'Read More' } } = props
  if (!story?.tags || story?.tags?.length <= 0) return <></>

  const sortedTags = [...story.tags].sort((a, b) => {
    return a.tag.toLowerCase().localeCompare(b.tag.toLowerCase())
  })

  return (
    <>
      <SectionHeading title={item.title} />
      <section className={singleStoryStyles.more}>
        {sortedTags.map(interest => {
          const category =
            interest.category === 'fw_therapeutic_category'
              ? 'drug_classes'
              : interest.category
          return (
            <Link
              to={`/river/tag/${encodeURIComponent(
                category
              )}/${encodeURIComponent(interest.tag)}`}
              key={`${interest.category}-${interest.tag}`}
              className={singleStoryStyles.link}
              data-piwik-class="view-more-tag"
              data-piwik-name={`${interest.category}-${interest.tag}`}
            >
              {interest.tag}
            </Link>
          )
        })}
      </section>
    </>
  )
}

export const LoopItem_notify_me_updates_button = props => {
  const { story = null } = props

  const isTherapyTrends = ['Therapy Trends'].includes(
    story?.reportSettings?.reportBrand
  )

  if (!story || !isTherapyTrends) {
    return null
  }
  return <NotifyUpdates {...props} />
}

export const LoopItem_report_related_updates = props => {
  const viewProps = useViewProps(props)
  const { item = {}, className = '', story } = props

  return (
    <ReportRelatedUpdates
      className={className}
      style={item?.customStyles}
      handleViewMoreClick={viewProps?.handleViewMoreClick}
      story={story}
      {...item}
    />
  )
}
