import React, { Fragment, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { ProtectedArea, ShowFor } from 'src/components/LicenseProtected'
import Typography from 'src/components/ui/Typography'
import MenuItem from 'src/components/ui/Menu/MenuItem'
import FieldButton from 'src/components/ui/FieldButton'
import { Input } from 'src/components/ui/Input'
import {
  licenseTypes,
  audienceTypes
} from 'src/services/FW5ML/parsers/UserParser'
import { getConfig } from 'src/modules/Config/selectors'
import { SessionContext } from 'src/modules/Session/context'
import styles from './MobileMenuTopItems.module.sass'
import { actions as paywallActions } from 'src/modules/Paywall/slice'

const MobileMenuTopItems = ({
  position,
  doSearchCall,
  location,
  history,
  historyHandler
}) => {
  const { user } = useContext(SessionContext)
  const handleSearchItemClick = event => event.stopPropagation()
  const [term, setTerm] = useState('')
  const search = () => {
    if (!term) return
    doSearchCall(term)
    setTerm('')
  }
  const handleInputChange = event => setTerm(event.target.value)
  const account_settings = useSelector(getConfig('account_settings')) || {}
  const {
    show_plus_upgrade = true,
    show_subscription_types = { free: true, plus: true }
  } = account_settings
  const dispatch = useDispatch()
  const handleClick = event => {
    event.preventDefault()
    dispatch(
      paywallActions.updatePaywallVisible({
        visible: true,
        type: 'plus_only',
        blurArticle: true,
        subtype: 'feature_plus'
      })
    )
  }

  return (
    <Fragment>
      {position === 'right' && (
        <Fragment>
          <ProtectedArea>
            <ShowFor authenticated>
              <div className={styles.userWrapper} data-private>
                <div
                  className={styles.userNameContainer}
                  data-private
                  data-clarity-mask="true"
                >
                  <Typography className={styles.userName}>
                    {`${user.firstName} ${user.lastName}`}
                  </Typography>
                  <ProtectedArea>
                    <ShowFor authenticated>
                      <Typography className={styles.subText}>
                        {(user.license === licenseTypes.LICENSE_PLUS
                          ? show_subscription_types.plus && audienceTypes.PLUS
                          : show_subscription_types.free &&
                            audienceTypes.FREE) || ''}
                      </Typography>
                    </ShowFor>
                  </ProtectedArea>
                </div>
                <ProtectedArea>
                  {show_plus_upgrade && (
                    <ShowFor notPlus>
                      <div
                        onClick={handleClick}
                        className={styles.upgradeButton}
                      >
                        Upgrade to PLUS
                      </div>
                    </ShowFor>
                  )}
                </ProtectedArea>
              </div>
            </ShowFor>
          </ProtectedArea>
          <ProtectedArea>
            <ShowFor anonymous>
              <div className={styles.anonymusSignIn}>
                <MenuItem
                  icon=""
                  className={
                    location.pathname === '/sign-in' ? styles.activeItem : ''
                  }
                  onClick={() =>
                    historyHandler('/sign-in', location, history, 'push')
                  }
                  data-piwik-class="menu-item"
                >
                  Sign In
                </MenuItem>
              </div>
            </ShowFor>
          </ProtectedArea>
          <ProtectedArea>
            <ShowFor anonymous>
              <div className={styles.anonymusSignIn}>
                <MenuItem
                  icon=""
                  className={
                    location.pathname === '/register' ? styles.activeItem : ''
                  }
                  onClick={() =>
                    historyHandler('/register', location, history, 'push')
                  }
                  data-piwik-class="menu-item"
                >
                  Register
                </MenuItem>
              </div>
            </ShowFor>
          </ProtectedArea>
          <div className={styles.divider} />
        </Fragment>
      )}
      {position === 'left' && (
        <div onClick={handleSearchItemClick} className={styles.searchItem}>
          <Input
            onChange={handleInputChange}
            defaultValue={term}
            placeholder="Search"
            className={styles.serachInput}
            onEnterKeyDown={search}
            rightElements={
              <FieldButton
                icon="svg/material-design-icons/action/search"
                onClick={search}
                colorTier="action"
              />
            }
          />
        </div>
      )}
    </Fragment>
  )
}

MobileMenuTopItems.propTypes = {
  position: PropTypes.string,
  doSearchCall: PropTypes.func,
  location: PropTypes.object,
  history: PropTypes.object,
  historyHandler: PropTypes.func
}

export default MobileMenuTopItems
