import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const myAccountSlice = createSlice({
  name: 'myAccountReducer',
  initialState,
  reducers: {
    requestAccountUpdate: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'request',
          asyncKey: 'accountUpdate'
        }
      }),
      reducer: () => {}
    },
    successAccountUpdate: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'success',
          asyncKey: 'accountUpdate'
        }
      }),
      reducer: () => {}
    },
    requestAccountUpdate: {
      prepare: payload => ({
        payload,
        meta: {
          asyncFlags: true,
          method: 'error',
          asyncKey: 'accountUpdate'
        }
      }),
      reducer: () => {}
    }
  }
})

export const { actions, reducer } = myAccountSlice

export const {
  requestAccountUpdate,
  successAccountUpdate,
  errorAccountUpdate
} = actions

export default reducer
