import React, { Fragment } from 'react'
import RoutedLink from 'src/components/RoutedLink'
import { takeEvery, call, all, put, select } from 'redux-saga/effects'
import { notificationDuration as duration } from 'src/constants'
import { replace, push } from 'connected-react-router'
import { actions } from './slice'
import { actions as catalogActions } from 'src/modules/Catalogs/slice'
import { getCatalogs } from 'src/modules/Catalogs/selector'
import { notificationsEnqueue } from 'src/modules/Notifications/slice'
import { logPush } from 'src/modules/ContactUs/slice'
import FW5MLError from 'src/services/FW5ML/FW5MLError'
import { logout } from 'src/modules/Session/actions'
import { ACCESS_DENIED, CONSENT_REQUIRED } from 'src/services/FW5ML/errorCodes'

function* pushErrorNotification() {
  yield put(push('/contact-us'))
  yield put(
    notificationsEnqueue({
      message: (
        <Fragment>
          Something went wrong! <RoutedLink label="Contact us" />
        </Fragment>
      ),
      duration
    })
  )
}

function* getLandingContentData(services, action) {
  const LandingPageRepository = services('LandingPageRepository')
  const Session = services('Session')
  const { payload } = action
  let { type, route } = payload
  const location = yield select(state => state.router.location)
  const { pathname } = location
  try {
    const cookie = yield call([Session, 'getCookie'], 'current_flow')
    if (cookie) {
      if (type !== 'landing') {
        route = cookie
      }
      yield call([Session, 'removeCookie'], 'current_flow')
    }

    // Return to home if there is no route/cookie defined
    if (!route || route === '') {
      yield put(replace(`/`))
      return
    }

    const { configs } = yield call(
      [LandingPageRepository, 'getLandingContentData'],
      route
    )
    const data = configs[0]

    yield put(actions.successLandingContentData(data))
    if (pathname !== `/${route}`) {
      yield put(replace(`/${route}`))
    }
  } catch (e) {
    yield put(actions.errorLandingContentData({ error: e.message }))
    yield put(
      logPush(
        'Landing Page Data',
        `Error while retrieving landing page content data: ${e.message}`
      )
    )
    if (e instanceof FW5MLError) {
      const code = e.getCode()
      if (code === ACCESS_DENIED) {
        yield put(logout({ reload: false, redirect: '/sign-in' }))
        yield put(
          notificationsEnqueue({
            message: 'Please sign in and try again.'
          })
        )
        return
      }
      if (code === CONSENT_REQUIRED) {
        yield call([Session, 'setCookie'], 'current_flow', route)
        const user = yield call([Session, 'userLoad'])
        if (!user) {
          yield put(replace(`/sign-in`))
          throw new Error('Please sign in and try again.')
        } else if (pathname !== '/consent') {
          yield put(replace(`/consent`))
        }

        return
      }
      yield call(pushErrorNotification)
      return
    }
  }
}

function* getLandingCompanyData(services, action) {
  const LandingPageRepository = services('LandingPageRepository')
  const CatalogsRepository = services('CatalogsRepository')
  const Session = services('Session')
  const Piwik = services('Piwik')
  const { payload } = action
  let { type, route } = payload
  try {
    if (type !== 'landing') {
      route = yield call([Session, 'getCookie'], 'current_flow')
    }

    // Track pageview for login, registration and consent
    const trackingRoute = route && route !== '' ? route + '/' : ''
    switch (type) {
      case 'login':
        yield call([Piwik, 'track'], 'page', 'view', `${trackingRoute}sign-in`)
        break
      case 'registration':
        yield call([Piwik, 'track'], 'page', 'view', `${trackingRoute}register`)
        break
      case 'consent':
        yield call([Piwik, 'track'], 'page', 'view', `${trackingRoute}consent`)
        break
      default:
        break
    }

    // Return if there is no route/cookie defined
    if (!route || route === '' || route.includes('notify-me-subscribe')) {
      if (type === 'consent') {
        yield put(replace(`/`))
      }
      yield put(
        actions.errorLandingCompanyData({ type, error: 'no cookie found' })
      )
      return
    }

    let { landingPages } = yield select(getCatalogs)
    if (!landingPages) {
      const configs = yield call([CatalogsRepository, 'getCatalog'], 'configs')
      yield put(
        catalogActions.successCatalogs({
          key: 'landingPages',
          catalog: configs.landing_pages || {}
        })
      )
    }

    landingPages = yield select(state => state?.catalogsReducer?.landingPages)

    if (!landingPages) {
      yield put(
        actions.errorLandingCompanyData({
          error: 'landing pages catalog is null'
        })
      )
      return
    }

    const validRoute = yield call(
      [LandingPageRepository, 'validateCompanyRoute'],
      {
        landingPages,
        uri_path: route
      }
    )

    if (!validRoute) {
      yield put(
        actions.errorLandingCompanyData({ error: 'invalid landing page route' })
      )
      yield put(replace(`/not-found`))
      return
    }

    let data = null

    if (type !== 'landing') {
      const { configs } = yield call(
        [LandingPageRepository, 'getLandingCompanyData'],
        {
          type,
          uri_path: route
        }
      )
      data = configs[0]
    }

    // Call content saga function to validate consent and get landing page data
    if (type === 'landing' || type === 'consent') {
      yield call(getLandingContentData, services, action)
    }
    yield put(actions.successLandingCompanyData({ type, data }))
  } catch (e) {
    yield put(actions.errorLandingCompanyData({ type, error: e.message }))
    yield put(
      logPush(
        'Landing Page Company',
        `Error while retrieving landing page company data: ${e.message}`
      )
    )
    if (e instanceof FW5MLError) {
      const code = e.getCode()
      if (code === ACCESS_DENIED) {
        yield put(logout({ reload: false, redirect: '/sign-in' }))
        yield put(
          notificationsEnqueue({
            message: 'Please sign in and try again.'
          })
        )
        return
      }
      yield call(pushErrorNotification)
      return
    }
  }
}

function* confirmConsent(services, action) {
  const LandingPageRepository = services('LandingPageRepository')
  const Session = services('Session')
  try {
    const user = yield call([Session, 'userLoad'])
    if (!user) {
      yield put(replace(`/sign-in`))
      throw new Error('Please sign in and try again.')
    }
    const route = yield call([Session, 'getCookie'], 'current_flow')

    // Return to home if there is no route/cookie defined
    if (!route || route === '') {
      yield put(replace(`/`))
      return
    }

    yield call([LandingPageRepository, 'confirmConsent'], {
      user,
      uri_path: route
    })
    yield put(actions.successConfirmConsent(true))
    yield put(replace(`/${route}`))
  } catch (e) {
    yield put(actions.errorConfirmConsent({ error: e.message }))
    yield put(
      logPush(
        'Consent Form',
        `Error while submitting the consent form: ${e.message}`
      )
    )
    if (e instanceof FW5MLError) {
      const code = e.getCode()
      if (code === ACCESS_DENIED) {
        yield put(logout({ reload: false, redirect: '/sign-in' }))
        yield put(
          notificationsEnqueue({
            message: 'Please sign in and try again.'
          })
        )
        return
      }
      yield call(pushErrorNotification)
      return
    }
  }
}

export default function* watchUpdate(services) {
  yield all([
    takeEvery(
      actions.requestLandingContentData,
      getLandingContentData,
      services
    ),
    takeEvery(
      actions.requestLandingCompanyData,
      getLandingCompanyData,
      services
    ),
    takeEvery(actions.requestConfirmConsent, confirmConsent, services)
  ])
}
