import { History, LiveHelp } from '@mui/icons-material'
import PropTypes from 'prop-types'
import { Box, IconButton, Typography, styled } from '@mui/material'
import { t } from 'i18next'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { actions as chatPageActions } from 'src/modules/ChatPage/ChatPageSlice'
import { MessageTextSquareIcon } from 'src/static/icons'

const ButtonBarContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '69px',
  width: '100%',
  padding: 2,
  justifyContent: 'center',
  border: `solid 1px ${'#fff'}`,
  overflowY: 'hidden',
  boxSizing: 'border-box'
}))

const BarButtonContainer = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  margin: '0px 12px',
  height: 'fit-content',
  minHeight: '42px',
  justifyContent: 'space-between',
  '> svg': {
    margin: '0px auto 4px'
  },
  '> h1': {
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: '13px',
    color: '#363e4e',
    textAlign: 'center'
  },
  '@media all and (max-width: 340px)': {
    margin: '0px 6px'
  },
  '@media all and (max-width: 290px)': {
    margin: '0px 3px'
  }
}))

const BarButton = ({ icon, label, onClick }) => {
  return (
    <BarButtonContainer onClick={onClick}>
      <IconButton sx={{ color: '#363e4e' }}>{icon}</IconButton>
      <Typography variant="h1">{label}</Typography>
    </BarButtonContainer>
  )
}

BarButton.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string,
  onClick: PropTypes.func
}

export const MobileButtonBar = () => {
  const dispatch = useDispatch()

  const handleShowContainer = container => {
    dispatch(chatPageActions.displayContainer({ container }))
  }

  return (
    <ButtonBarContainer data-testid="mobile-button-bar">
      <BarButton
        icon={<History />}
        label={t('history')}
        onClick={() => handleShowContainer('history')}
      />
      <BarButton
        icon={<LiveHelp />}
        label={t('chatBoxFaqs')}
        onClick={() => handleShowContainer('faqs')}
      />
      <Link to="contact-us" style={{ textDecoration: 'none' }}>
        <BarButton icon={<MessageTextSquareIcon />} label={'Feedback'} />
      </Link>
    </ButtonBarContainer>
  )
}
