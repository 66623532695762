import { createSlice } from '@reduxjs/toolkit'
import { checkIfArray } from '../../utils'

const initialState = {
  bootstrapped: false
}

const configSlice = createSlice({
  name: 'configReducer',
  initialState,
  reducers: {
    initialConfigRequest: state => {
      state.bootstrapped = false
    },
    initialConfigSuccess: state => {
      state.bootstrapped = true
    },
    addConfigRequest: (state, { payload: { key } }) => {
      state[key] = {
        ready: false,
        error: null,
        data: null
      }
    },
    addConfigSuccess: (state, { payload: { key, data } }) => {
      state[key] = {
        ready: true,
        error: null,
        data
      }
    },
    addConfigError: (state, { payload: { key, error } }) => {
      state[key] = {
        ready: true,
        error,
        data: null
      }
    },
    updateConfigRequest: (state, { payload: { key } }) => {
      const prevData = state[key] ? state[key].data : null
      const data = checkIfArray(prevData) ? [...prevData] : { ...prevData }

      state[key] = {
        ready: false,
        error: null,
        data
      }
    },
    updateConfigSuccess: (state, { payload: { key, updatedData } }) => {
      const prevData = state[key] ? state[key].data : null
      const data = checkIfArray(prevData)
        ? [...prevData, ...updatedData]
        : { ...prevData, ...updatedData }

      state[key] = {
        ready: true,
        error: null,
        data
      }
    },
    updateConfigError: (state, { payload: { key, error } }) => {
      const prevData = state[key] ? state[key].data : null
      const data = checkIfArray(prevData) ? [...prevData] : { ...prevData }

      state[key] = {
        ready: true,
        error,
        data
      }
    },
    getConfigRegisterModalValidation: () => {}
  }
})

export const { reducer, actions } = configSlice

export const {
  initialConfigRequest,
  initialConfigSuccess,
  addConfigRequest,
  addConfigSuccess,
  addConfigError,
  updateConfigRequest,
  updateConfigSuccess,
  updateConfigError,
  getConfigRegisterModalValidation
} = actions

export default reducer
