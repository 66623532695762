import { useEffect, useRef } from 'react'
import parse, { domToReact } from 'html-react-parser'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Box, IconButton, Typography, styled } from '@mui/material'

import { getIsTyping } from 'src/modules/DougallChat/ChatSelector'

import ActionBar from 'src/components/ActionBar/ActionBar'
import Warning from 'src/components/Warning/Warning'
import { EditIcon } from 'src/static/icons'

import answerLogo from 'src/images/fwai_answer.svg'
import usePiwik from 'src/hooks/usePiwik'

/**
 * Message Container
 * theme path: Message.styleOverrides.container
 */
const MessageContainer = styled(Box, {
  name: 'Message',
  label: 'messageContainer',
  overridesResolver: (props, styles) => [styles.container],
  shouldForwardProp: prop => !['isAnswer'].includes(prop)
})(({ theme, isAnswer }) => ({
  width: '100%',
  overflowX: 'clip',
  overflowY: 'auto',
  marginTop: '15px',
  paddingBottom: '0px',
  marginLeft: isAnswer ? '0px' : '50px',
  '& .text': {
    width: isAnswer ? '100%' : '92%',
    color: isAnswer
      ? theme.palette.message?.answerText
      : theme.palette.message?.promptText,
    fontSize: '14px',
    lineHeight: isAnswer ? '1.43' : '1.5',
    whiteSpace: 'pre-line',
    tabSize: 8,
    wordWrap: 'break-word',
    '> a, > strong > a': { margin: 0 }
  }
}))

/**
 * Title Container
 * theme path: Message.styleOverrides.titleContainer
 */
const TitleContainer = styled(Box, {
  name: 'Message',
  label: 'messageTitleContainer',
  overridesResolver: (props, styles) => [styles.titleContainer]
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '10px',
  justifyContent: 'space-between',
  '& .titleImg': {
    maxHeight: '12px',
    maxWidth: '130px',
    marginRight: '5px'
  },
  '& .title': {
    color: theme.palette.message?.titleText,
    fontSize: '12px',
    lineHeight: '13px'
  },
  '& .MDContainer': {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    '& .MDImg': { maxWidth: '130px', height: '21px' },
    '& .MDTitle': {
      width: '123px',
      color: theme.palette.message?.mdTitleText,
      fontSize: '12px',
      lineHeight: '13px',
      marginTop: '3px'
    }
  }
}))

/**
 * Content Container
 * theme path: Message.styleOverrides.contentContainer
 */
const ContentContainer = styled(Box, {
  name: 'Message',
  label: 'messageContentContainer',
  overridesResolver: (props, styles) => [styles.contentContainer],
  shouldForwardProp: prop => !['isAnswer'].includes(prop)
})(({ theme, isAnswer }) => ({
  overflowY: 'auto',
  backgroundColor: isAnswer
    ? '#f8f9fc'
    : theme.palette.message?.promptBackground,
  padding: '25px 20px',
  borderRadius: '16px',
  boxShadow: isAnswer
    ? '0 1px 3px 0 rgba(16, 24, 40, 0.1), 0 1px 2px 0 rgba(16, 24, 40, 0.06)'
    : 'none',
  border: isAnswer
    ? `solid 1px ${theme.palette.message?.answerBorder}`
    : 'none',
  position: 'relative',
  backgroundImage: isAnswer
    ? `
    linear-gradient(to bottom, hsla(0, 0%, 100%, 0.75) 0%, hsla(0, 0%, 100%, 0) 90%),
    radial-gradient(ellipse at left top, hsla(0, 0%, 100%, 0.5) 0%, hsla(0, 0%, 100%, 0.3) 60%)
  `
    : '',
  '& .textContainer': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .editIcon': {
      fontSize: '16px',
      cursor: 'pointer',
      margin: '0',
      'g > g': {
        fill: theme.palette.message?.promptEditIcon
      }
    }
  }
}))
/**
 * Highlight Tag
 * theme path: Message.styleOverrides.highlightTag
 */
const HightlightTag = styled('a', {
  name: 'Message',
  label: 'messageHighlightTag',
  overridesResolver: (props, styles) => [styles.highlightTag]
})(({ theme }) => ({
  cursor: 'pointer',
  textDecoration: 'underline',
  color: theme.palette.message?.highlight,
  margin: '0px 8px',
  fontWeight: 'bold',
  '> strong': { color: theme.palette.message?.highlight }
}))

const Message = ({
  index = '',
  isAnswer = true,
  isDougall = false,
  showWarning = true,
  title,
  message = '',
  prompt = '',
  editable = true,
  onEditClick,
  onAction,
  promptId = '',
  answerId = '',
  tags = [],
  showSocial = true,
  date = '',
  showShare,
  showLikes,
  done = false,
  onTypingFinish
}) => {
  const { handleTracking } = usePiwik()
  const isTyping = useSelector(getIsTyping())
  const messageRef = useRef(null)

  // Get date format
  const dateFormat =
    date &&
    moment(date).calendar(null, {
      sameDay: '[Today] hh:mma',
      lastDay: '[Yesterday] hh:mma',
      lastWeek: 'dddd hh:mma',
      sameElse: 'DD/MM/YYYY hh:mma'
    })

  // Remove initial and last break lines & Special Character Formatting
  const trimmed = message
    .replace(/(^\n+)|(\n+$)/g, '')
    .replace(/\\n/g, '\n')
    .replace(/\\"/g, '"')
  const bolded = trimmed.replace(/\*\*(.*?)\*\*/gm, '<strong>$1</strong>')
  const tabbed = bolded.replace('\t', '&nbsp;&nbsp;&nbsp;&nbsp;')

  const parserOptions = {
    replace: domNode => {
      const { name, attribs, children } = domNode

      if (name === 'span' && attribs.id) {
        const tag = tags.find(t => t.tagId === attribs.id)

        if (tag) {
          const { tagSource = '' } = tag
          const highlightText = domToReact(children)
          const destURL = tagSource
          return (
            <HightlightTag
              href={destURL}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                handleTracking(
                  'firstword-ai',
                  'trusted-link-body-click',
                  highlightText
                )
              }}
            >
              {highlightText}
            </HightlightTag>
          )
        }
      }
    }
  }

  let highlighted

  if (isAnswer) {
    if (isDougall) {
      highlighted = ''
    } else {
      highlighted = parse(tabbed, parserOptions)
    }
  } else {
    highlighted = message
  }

  useEffect(() => {
    if (isAnswer && isDougall && !isTyping) {
      messageRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
    // eslint-disable-next-line
  }, [isAnswer])

  useEffect(() => {
    if (done) {
      onTypingFinish()
    }
  }, [done])

  useEffect(() => {
    if (isTyping) {
      const resizeObserver = new ResizeObserver(() => {
        messageRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
      })
      resizeObserver.observe(messageRef.current)
      return () => {
        resizeObserver.disconnect()
      }
    }
    // eslint-disable-next-line
  }, [isTyping])

  return (
    <div data-testid="message" ref={messageRef}>
      <Box display="flex">
        <MessageContainer isAnswer={isAnswer}>
          <TitleContainer>
            {isAnswer && !isDougall ? (
              <>
                <img alt={'Answer Logo'} src={answerLogo} />
                <Typography className="title">{dateFormat}</Typography>
              </>
            ) : (
              <>
                {!isDougall && (
                  <>
                    <Typography className="title">{title}</Typography>
                    <Typography className="title">{dateFormat}</Typography>
                  </>
                )}
              </>
            )}
          </TitleContainer>
          {!isDougall && message && (
            <ContentContainer isAnswer={isAnswer}>
              <Box className="textContainer">
                <Typography className="text">{highlighted}</Typography>
                {!isAnswer && editable && (
                  <IconButton
                    data-testid="handleMessageEdit"
                    onClick={() => onEditClick(message)}
                    onKeyDown={event =>
                      event.key === 'Enter' && onEditClick(message)
                    }
                    disableRipple
                  >
                    <EditIcon className="editIcon" />
                  </IconButton>
                )}
              </Box>
              {isAnswer && showSocial && (
                <ActionBar
                  onAction={onAction}
                  prompt={prompt}
                  promptId={promptId}
                  answerId={answerId}
                  showShare={showShare}
                  showLikes={showLikes}
                />
              )}
              {showWarning && isAnswer && <Warning boxed />}
            </ContentContainer>
          )}
        </MessageContainer>
      </Box>
    </div>
  )
}

Message.propTypes = {
  /**
   * Message Index
   */
  index: PropTypes.string,
  /**
   * Boolean to differentiate between user message and response
   */
  isAnswer: PropTypes.bool,
  /**
   * Boolean to differentiate between chatGPT and Dougall.MD responses
   */
  isDougall: PropTypes.bool,
  /**
   * Boolean to show warning in answer
   */
  showWarning: PropTypes.bool,

  /**
   * Message Title
   */
  title: PropTypes.string,
  /**
   * Message to display
   */
  message: PropTypes.string,
  /**
   * Prompt made for specific answer
   */
  prompt: PropTypes.string,
  /**
   * If a prompt can be edited or not
   */
  editable: PropTypes.bool,
  /**
   * When is user prompt, on edit icon click
   */
  onEditClick: PropTypes.func,
  /**
   * When it has actionBar, on like or dislike
   */
  onAction: PropTypes.func,
  /**
   * Prompt ID, if answer it refers to the prompt it answers
   */
  promptId: PropTypes.string,
  /**
   * In case of being an answer, the answer identifier
   */
  answerId: PropTypes.string,
  /**
   * In case of being an answer, the tags to display
   */
  tags: PropTypes.array,
  /**
   * In case of being an answer from Dougall, the urls list to display
   */
  urls: PropTypes.array,
  /**
   * Boolean to show social block
   */
  showSocial: PropTypes.bool,
  /**
   * Message date
   */
  date: PropTypes.string,
  /**
   * Boolean to show Share Button or not
   */
  showShare: PropTypes.bool,
  /**
   * Boolean to show Like/Dislike button
   */
  showLikes: PropTypes.bool,
  /**
   * Boolean to know if the app has received the complete message from stream
   */
  done: PropTypes.bool,
  /**
   * Function excecuted when typewriter message animation is finished
   */
  onTypingFinish: PropTypes.func
}

export default Message
