import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import useMediaSize from 'src/hooks/useMediaSize'
import { getDisplayContainer } from 'src/modules/ChatPage/ChatPageSelector'
import { getFetching } from 'src/modules/DougallChat/ChatSelector'
import { actions as chatPageActions } from 'src/modules/ChatPage/ChatPageSlice'

import styles from './ChatPage.module.sass'
import { Box, styled } from '@mui/material'

const cx = classNames.bind(styles)

const LeftContainer = styled(Box, {
  name: 'ChatPage',
  label: 'LeftContainer',
  overridesResolver: (props, styles) => [styles.leftContainer],
  shouldForwardProp: prop => !['hide'].includes(prop)
})(({ theme, hide }) => ({
  boxSizing: 'border-box',
  backgroundColor: 'white',
  height: '100%',
  overflowY: 'auto',
  maxWidth: '20%',
  padding: '20px 20px 0',
  boxShadow:
    '0 1px 3px 0 rgba(16, 24, 40, 0.1), 0 1px 2px 0 rgba(16, 24, 40, 0.06)',
  borderTopRightRadius: '20px',
  paddingTop: '16px !important',
  marginLeft: '0px',
  '@media all and (max-width: 860px)': {
    maxWidth: 'unset',
    display: hide ? 'none' : 'block'
  },
  '.closeContainer': {
    position: 'relative',
    width: '100%'
  }
}))

/**
 * Right Container
 * theme path: ChatPage.styleOverrides.rightContainer
 */
const RightContainer = styled(Box, {
  name: 'ChatPage',
  label: 'RightContainer',
  overridesResolver: (props, styles) => [styles.leftContainer],
  shouldForwardProp: prop => !['hide'].includes(prop)
})(({ theme, hide }) => ({
  boxSizing: 'border-box',
  backgroundColor: 'white',
  height: '100%',
  overflowY: 'auto',
  maxWidth: '30%',
  padding: '20px 20px 0',
  boxShadow:
    '0 1px 3px 0 rgba(16, 24, 40, 0.1), 0 1px 2px 0 rgba(16, 24, 40, 0.06)',
  borderTopLeftRadius: '20px',
  paddingTop: '16px !important',
  marginLeft: '0px',
  '@media all and (max-width: 860px)': {
    maxWidth: 'unset',
    display: hide ? 'none' : 'block'
  },
  '.closeContainer': {
    position: 'relative',
    width: '100%'
  }
}))

const ChatPage = ({
  children,
  className,
  leftContent,
  rightContent,
  footer
}) => {
  const dispatch = useDispatch()
  const mediaSize = useMediaSize({
    mediaQueries: {
      small: '(max-width: 860px)'
    }
  })
  const displayContainer = useSelector(getDisplayContainer())

  const isFetching = useSelector(getFetching())

  const rightScroll = useRef(null)
  useEffect(() => {
    if (!isFetching) {
      setTimeout(() => {
        if (rightScroll?.current) {
          rightScroll.current.scrollTo({ top: 0 })
        }
      }, 500)
    }
  }, [isFetching])

  const handleCloseClick = () => {
    dispatch(chatPageActions.displayContainer({ container: 'chat' }))
  }

  return (
    <div id="chatPage_container" className={cx(styles.page, className)}>
      {mediaSize !== 'small' && (
        <LeftContainer
          id="listingContainer"
          ref={rightScroll}
          hide={displayContainer === 'chat'}
        >
          {mediaSize === 'small' && (
            <div className={styles.closeContainer}>
              <IconButton
                sx={{
                  position: 'absolute',
                  top: '-15px',
                  right: '0',
                  width: '45px',
                  height: '45px'
                }}
                onClick={handleCloseClick}
                size="large"
              >
                <CloseIcon sx={{ fontSize: '20px' }} />
              </IconButton>
            </div>
          )}
          {leftContent}
        </LeftContainer>
      )}
      <div
        className={cx(styles.middleContainer, {
          hideContainer: displayContainer !== 'chat'
        })}
        style={{
          overflowY: 'scroll',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': { display: 'none' }
        }}
      >
        <div className={styles.middleChildren}>{children}</div>
        <div className={styles.footer}>{footer}</div>
      </div>
      <RightContainer
        id="listingContainer"
        ref={rightScroll}
        hide={displayContainer === 'chat'}
      >
        {mediaSize === 'small' && (
          <div className="closeContainer">
            <IconButton
              data-testid="closeContainer"
              sx={{
                position: 'absolute',
                top: '-15px',
                right: '0',
                width: '45px',
                height: '45px'
              }}
              onClick={handleCloseClick}
            >
              <CloseIcon sx={{ fontSize: '20px' }} />
            </IconButton>
          </div>
        )}
        {rightContent}
      </RightContainer>
    </div>
  )
}
ChatPage.propTypes = {
  /**
   * The page children
   */
  children: PropTypes.node,
  /**
   * A className applied to the children container element
   */
  className: PropTypes.string,
  /**
   * Content to be displayed on the left panel
   */
  leftContent: PropTypes.node,
  /**
   * Content to be displayed on the right panel
   */
  rightContent: PropTypes.node,
  /**
   * Footer Component
   */
  footer: PropTypes.node
  /**
   * If false Footer section will not be shown
   */
  // showFooter: PropTypes.bool
}

export default ChatPage
