import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { getDateChunk } from 'src/utils'
import PropTypes from 'prop-types'

import Message from 'src/components/Message/Message'
import TextDivider from 'src/components/TextDivider/TextDivider'
import { Box, CircularProgress, styled } from '@mui/material'

import siteConfig from 'src/site.config.json'
const { sections } = siteConfig

const StreamContainer = styled(Box, {
  name: 'MessagesStream',
  label: 'messagesStreamContainer',
  overridesResolver: (props, styles) => [styles.container]
})({
  overflowY: 'auto',
  msOverflowStyle: 'none', // Scrollbar hidden for IE & Edge
  scrollbarWidth: 'none', // Scrollbar hidden for Firefox
  '&::-webkit-scrollbar': { display: 'none' } // Scrollbar hidden for Chrome, Safari & Opera
})

const MessageStream = ({
  messages = [],
  onEditClick,
  onAction,
  isLoading,
  isTyping,
  showShare,
  showLikes,
  onTypingFinish
}) => {
  const { t } = useTranslation()
  const {
    chat: { user, chatGPT, dougallMD }
  } = sections

  const scrollable = useRef(null)

  useEffect(() => {
    // Autoscroll to your last prompt
    scrollable.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
  }, [isLoading])

  useEffect(() => {
    // Scroll to Top on messages reset (for mobile)
    if (messages.length === 2) {
      scrollable.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [messages])

  const handleTypingFinish = () => {
    onTypingFinish()
    scrollable.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
  }

  return (
    <StreamContainer data-testid="messages-stream" ref={scrollable}>
      {messages.map((messageInfo, idx) => {
        const {
          promptId = '',
          answerId = '',
          isAnswer,
          isDougall,
          showWarning,
          message,
          answer,
          hcpPrompt,
          editable,
          tags,
          urls,
          showSocial,
          isError,
          date,
          done,
          isDivider = false
        } = messageInfo

        let profileIcon

        if (isAnswer) {
          if (isDougall) {
            profileIcon = dougallMD?.defaultIcon
          } else {
            profileIcon = chatGPT?.defaultIcon
          }
        } else {
          profileIcon = user?.defaultIcon
        }

        let title

        if (isAnswer) {
          if (!isDougall) {
            title = t('answerFromGPT')
          }
        } else {
          title = t('you')
        }

        if (isDivider) {
          return <TextDivider key={idx} text={getDateChunk(date)} />
        }

        return (
          <Box
            id={isAnswer ? `answer-${idx}` : `prompt-${idx}`}
            key={idx}
            sx={{ marginBottom: idx !== messages.length - 1 ? '5px' : '0px' }}
          >
            <Message
              index={`${idx}`}
              isAnswer={isAnswer}
              isDougall={isDougall}
              showWarning={showWarning}
              icon={profileIcon}
              title={title}
              message={message ?? answer}
              prompt={hcpPrompt}
              editable={editable}
              onEditClick={onEditClick}
              onAction={onAction}
              promptId={promptId}
              answerId={answerId}
              tags={tags}
              urls={urls}
              showSocial={showSocial}
              isError={isError}
              date={date}
              showShare={showShare}
              showLikes={showLikes}
              done={done}
              onTypingFinish={handleTypingFinish}
            />
          </Box>
        )
      })}
      {isLoading && !isTyping && (
        <Box display="flex" justifyContent="center" margin="10px 0px">
          <CircularProgress
            size={'55px'}
            thickness={4}
            sx={{ color: '#293056' }}
          />
        </Box>
      )}
    </StreamContainer>
  )
}

MessageStream.propTypes = {
  /**
   * Messages to display
   */
  messages: PropTypes.array,
  /**
   * When is user prompt, on edit icon click
   */
  onEditClick: PropTypes.func,
  /**
   * When it has actionBar, on like or dislike
   */
  onAction: PropTypes.func,
  /**
   * Boolean when answer is fetching
   */
  isLoading: PropTypes.bool,
  /**
   * Boolean when an anwer is being typed
   */
  isTyping: PropTypes.bool,
  /**
   * Boolean to show Share Button or not
   */
  showShare: PropTypes.bool,
  /**
   * Boolean to show Like/Dislike Button
   */
  showLikes: PropTypes.bool,
  /**
   * Function excecuted when current typewriter message animation finishes
   */
  onTypingFinish: PropTypes.func
}

export default MessageStream
