import { compose } from 'redux'
import { connect } from 'react-redux'
import RiverBody from '../components/RiverBody'

const mapDispatchToProps = dispatch => ({})

const mapStateToProps = state => ({
  ads: state.adsReducer
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(RiverBody)
