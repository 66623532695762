import { useForm } from 'react-hook-form'
import FeedbackModal from 'src/components/DougallModal/FeedbackModal'
import { useChat } from 'src/modules/DougallChat/hooks'
import usePiwik from 'src/hooks/usePiwik'

const ActionModals = () => {
  // Hooks
  const {
    activeModal,
    actionHandler,
    parentPromptId,
    actionSubmit,
    setActiveModal
  } = useChat()

  // Variables
  const { answerId, promptId } = actionHandler
  const { handleTracking } = usePiwik()

  // Feedback Form hook
  const feedbackMethods = useForm({
    mode: 'onTouched',
    defaultValues: { feedback: '' }
  })
  const { setValue, getValues, reset } = feedbackMethods

  const dispatchAction = ({ feedback }) => {
    actionSubmit({
      ...actionHandler,
      parentPromptId: parentPromptId ? parentPromptId.toString() : undefined,
      feedback
    })
    setActiveModal(null)
  }

  /**
   * Feedback Form handler
   * @param {boolean} dismissedFeedback
   */
  const handleActionSubmit = dismissedFeedback => {
    if (dismissedFeedback) {
      dispatchAction({ feedback: '' })
    } else {
      const { feedback } = getValues()
      if (feedback.trim()) {
        handleTracking(
          'firstword-ai',
          'button-click',
          'feedback-overlay-submit',
          {
            answerId,
            promptId
          }
        )
        dispatchAction({ feedback })
        reset({ feedback: '' })
      }
    }
  }

  return (
    <form>
      <FeedbackModal
        open={activeModal === 'feedback'}
        onCancel={() => handleActionSubmit(true)}
        onConfirm={() => handleActionSubmit(false)}
        onTextAreaChange={e => {
          setValue('feedback', e.target.value.trim())
        }}
      />
    </form>
  )
}

export default ActionModals
