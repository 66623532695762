import { put, all, takeEvery } from 'redux-saga/effects'
import { actions as themeActions } from 'src/modules/themeProvider/ThemeProviderSlice'
import { notificationsEnqueue } from 'src/modules/Notifications/slice'
import siteConfig from 'src/site.config.json'

const { configuration } = siteConfig

/**
 * Extracts theme override values and exposes them to Redux state
 * @returns {Generator<*, void, *>}
 */
function* themeRequest() {
  try {
    yield put(
      themeActions.themeRequestSuccess({
        active: configuration.theme.active,
        list: configuration.theme.list
      })
    )
  } catch (e) {
    console.error(e)
    yield put(
      notificationsEnqueue({
        message: 'Theme is not available',
        duration: 0
      })
    )
    yield put(themeActions.themeRequestFail())
  }
}

export default function* watchTheme() {
  yield all([takeEvery(themeActions.themeRequest, themeRequest)])
}
