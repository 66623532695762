import { compose } from 'redux'
import { connect } from 'react-redux'
import { actions as landingPageActions } from '../slice'
import Landing from '../pages/Landing'
import { withServices } from '../../../services/ServiceContext'
import { withTitle } from '../../../utils/hocs'

const mapStateToProps = state => {
  return {
    content: state.landingPageReducer.content.config,
    consent: state.landingPageReducer.consent,
    busy: state.landingPageReducer.loading
  }
}

const mapDispatchToProps = dispatch => ({
  loadCompanyContent: data =>
    dispatch(landingPageActions.requestLandingCompanyData(data))
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withServices(resolve => ({
    Piwik: resolve('Piwik')
  })),
  withTitle
)(Landing)
