import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
import Typography from 'src/components/ui/Typography'
import styles from 'src/components/SingleStory/SingleStory.module.sass'
import { useSelector } from 'react-redux'
import { getConfig } from 'src/modules/Config/selectors'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

const ReportArticleSections = ({
  reportBrand,
  keyQuestions,
  keyBrands,
  participatingExperts
}) => {
  const { articleBrandsContent = {} } =
    useSelector(getConfig('reportsConfig')) || {}
  const reportBrandContent =
    articleBrandsContent[reportBrand] || articleBrandsContent?.shared || null

  const brandTitles = {
    Dossier: 'Key Companies'
  }
  const keyBrandsTitle = brandTitles[reportBrand] || 'Key Brands'

  const sections = [
    {
      title: 'Key Questions Answered',
      content: keyQuestions
    },
    {
      title: keyBrandsTitle,
      content: keyBrands
    },
    {
      title: 'Partial List of Participating Experts',
      content: participatingExperts
    },
    {
      title: 'Methodology',
      content: reportBrandContent?.methodologies
    },
    {
      title: 'What makes our reports different?',
      content: reportBrandContent?.whatMakes
    }
  ]

  return (
    <div>
      {sections.map((section, index) => (
        <Fragment key={`report_article_section_${index}`}>
          {section?.content && (
            <div className={styles.reportSection}>
              {section?.title && (
                <Typography
                  type="body1"
                  gutterBottom
                  className={cx(styles.body, styles.title)}
                >
                  {section.title}
                </Typography>
              )}
              <div className={cx(styles.body, styles.reportSectionContent)}>
                {ReactHtmlParser(section.content)}
              </div>
            </div>
          )}
        </Fragment>
      ))}
    </div>
  )
}

ReportArticleSections.propTypes = {
  reportBrand: PropTypes.string,
  keyQuestions: PropTypes.string,
  keyBrands: PropTypes.string,
  participatingExperts: PropTypes.string
}

export default ReportArticleSections
