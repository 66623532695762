import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { styled } from '@mui/material/styles'
import styles from './LeftBar.module.sass'
import { Box, Typography } from '@mui/material'

import History from 'src/modules/history/History'
import Faq from 'src/modules/faq/Faq'
import { ChatOutlined } from '@mui/icons-material'

import Svg from 'src/components/Svg'
import FwAIByDougall from 'src/images/powered.svg'
import usePiwik from 'src/hooks/usePiwik'

/**
 * Left Bar Container Styles
 */
const LeftBarContainer = styled(Box, {
  name: 'LeftBar',
  label: 'leftBarContainer',
  overridesResolver: (props, styles) => [styles.container]
})({
  height: 'calc(100% - 20px)',
  display: 'flex',
  flexDirection: 'column',
  '> .bottomSection': {
    marginTop: 'auto',
    width: '100%',
    overflowY: 'auto',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': { display: 'none' }
  }
})

/**
 * Left Bar Button Styles
 */
const ButtonStyled = styled(Box, {
  name: 'LeftBar',
  label: 'leftBarButton',
  overridesResolver: (props, styles) => [styles.button]
})(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  margin: '13px 10px',
  '> h1': {
    fontSize: '16px',
    lineHeight: '28px',
    color: theme.palette.leftBar.buttonText
  }
}))

// Adjusted IconContainer to fit your needs
const IconContainer = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#2d3e50',
  borderRadius: '50%',
  width: 26,
  height: 26,
  padding: 1,
  marginRight: 10
}))

/**
 * TitleIcon component
 */
const ButtonIcon = ({ icon: IconComponent, ...props }) => {
  return (
    <IconContainer>
      <IconComponent sx={{ color: 'white', fontSize: 18 }} {...props} />
    </IconContainer>
  )
}

ButtonIcon.propTypes = {
  icon: PropTypes.elementType
}

const LeftBarButton = ({ icon, label, onClick }) => {
  return (
    <ButtonStyled onClick={onClick}>
      <ButtonIcon icon={icon} />
      <Typography variant="h1">{label}</Typography>
    </ButtonStyled>
  )
}
LeftBarButton.propTypes = {
  icon: PropTypes.object,
  label: PropTypes.string,
  onClick: PropTypes.func
}

const LeftBar = ({ listExpanded = 0 }) => {
  const { handleTracking } = usePiwik()
  const history = useHistory()
  const { t } = useTranslation()
  // const { handleNewTopic } = useTopic()

  // 0 - None
  // 1 - Popular Prompts
  // 2 - History
  // 3 - FAQ
  const [listOpen, setListOpen] = useState(listExpanded)

  const listsOpenHandler = id => {
    let trackingAction = null

    if (id === 'history') {
      if (listOpen === 2) {
        trackingAction = 'collapse'
        setListOpen(0)
      } else {
        trackingAction = 'expand'
        setListOpen(2)
      }
    } else if (id === 'faq') {
      if (listOpen === 3) {
        trackingAction = 'collapse'
        setListOpen(0)
      } else {
        trackingAction = 'expand'
        setListOpen(3)
      }
    }

    if (trackingAction) {
      handleTracking('firstword-ai', trackingAction, id)
    }
  }

  const feedbackRedirect = () => {
    handleTracking('firstword-ai', 'click', 'feedback')
    return history.push('/contact-us', { from: history.location.pathname })
  }

  return (
    <LeftBarContainer data-testid="left-bar">
      <Svg src={FwAIByDougall.toString()} className={styles.fwLogo} />
      {/* <PopularPrompts openCallback={listsOpenHandler} isOpen={listOpen === 1} /> */}
      <History openCallback={listsOpenHandler} isOpen={listOpen === 2} />
      <div className="bottomSection">
        <Faq openCallback={listsOpenHandler} isOpen={listOpen === 3} />
        <LeftBarButton
          icon={ChatOutlined}
          label={t('feedbackLeftBar')}
          onClick={feedbackRedirect}
        />
      </div>
    </LeftBarContainer>
  )
}

LeftBar.propTypes = {
  /**
   * Number of list that should be expanded on mount
   */
  listExpanded: PropTypes.number
}

export default LeftBar
