import { createSlice } from '@reduxjs/toolkit'

const dynamicRiverSlice = createSlice({
  name: 'dynamicRiver',
  initialState: {},
  reducers: {
    requestDynamicRiverContent: (state, { payload }) => {
      const { riverId } = payload
      state[riverId] = {
        loading: true
      }
    },
    successDynamicRiverContent: (state, { payload }) => {
      const { riverId, data } = payload
      const { results = [], count = 0, aggs = {}, from = 0 } = data
      state[riverId] = {
        ...state[riverId],
        loading: false,
        loadingMore: false,
        results,
        count,
        aggs,
        page: 1,
        filter: { from }
      }
    },
    errorDynamicRiverContent: () => {},
    requestLoadMore: (state, { payload }) => {
      const { riverId } = payload
      state[riverId].loadingMore = true
      state[riverId].filter.from = state[riverId].page * 10
    },
    successLoadMore: (state, { payload }) => {
      const { riverId, data } = payload
      state[riverId].loadingMore = false
      state[riverId].results = [...state[riverId].results, ...data.results]
      state[riverId].page = state[riverId].page + 1
    }
  }
})

export const { actions, reducer } = dynamicRiverSlice

export const {
  requestDynamicRiverContent,
  successDynamicRiverContent,
  errorDynamicRiverContent,
  requestLoadMore,
  successLoadMore,
  errorLoadMore
} = actions

export default reducer
