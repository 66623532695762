import { useDispatch, useSelector } from 'react-redux'
import { actions as singleStoryActions } from 'src/modules/SingleStory/slice'
import { actions as viewActions } from 'src/modules/Views/slice'
import {
  getDisabled,
  getStory,
  getLoading, //Used for Pharma and HT
  getCombinedLoading //Used for Reports
} from 'src/modules/SingleStory/selector'
import { siteName } from 'src/utils/oneCodeBase'

const useSingleStory = () => {
  const isReports = siteName === 'Reports'
  const dispatch = useDispatch()
  const story = useSelector(getStory())

  const loading = isReports
    ? useSelector(getCombinedLoading()) // Use combinedLoading for Reports
    : useSelector(getLoading()) // Use conventional loading otherwise

  const disabledTags = useSelector(getDisabled())

  const getViewsPayload = id => {
    if (!id) return []

    const extraViewsParams = {
      article_id: [parseInt(id, 10)],
      related_to_article_id: [parseInt(id, 10)]
    }

    return [
      {
        name: 'recent news',
        ...extraViewsParams
      },
      {
        name: 'related analysis',
        ...extraViewsParams
      },
      {
        name: 'related news',
        ...extraViewsParams
      }
    ]
  }

  const requestSingleStory = id => {
    dispatch(singleStoryActions.requestSingleStory(id))
  }

  const requestStoryViews = id => {
    const views = getViewsPayload(id)
    dispatch(viewActions.requestViews(views))
  }

  const cleanSingleStory = () => {
    dispatch(singleStoryActions.cleanSingleStory())
  }

  const requestStoryContent = id => {
    if (!id) return null
    requestSingleStory(id)

    if (siteName !== 'Reports') {
      requestStoryViews(id)
    }
  }

  return {
    requestStoryContent,
    requestSingleStory,
    requestStoryViews,
    cleanSingleStory,
    story,
    loading,
    disabledTags
  }
}

export default useSingleStory
